import React from "react";
import {Button} from 'react-bootstrap';
import { BsFillPlusCircleFill } from "react-icons/bs";
class AddFilesButton extends React.Component {
    constructor(props) {
        super(props);

        this.fileInput = React.createRef();
    }

    filesSelected = () => {
        for(let i = 0; i < this.fileInput.current.files.length; i++) {
            let f = this.fileInput.current.files[i];
            this.props.appFunctions.addFileToItem(f, this.props.item);
        }
    }

    fileDialog = () => {
        this.fileInput.current.click();
    }

    render() {
        return (
            <div><input type="file" multiple ref={this.fileInput} style={{display: "none" }} onChange={ () => { this.filesSelected() } } />
                <Button onClick={this.fileDialog} className="add-files-button">
                    <BsFillPlusCircleFill />
                    {" " + this.props.message}
                </Button>
            </div>
        );
    }
  }

  export { AddFilesButton }