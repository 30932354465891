import React from "react";
import {Row, Col} from "react-bootstrap";

import { AddedAuthordisplay } from "./AddedAuthorDisplay";
import { AddAuthorField } from "./AddAuthorField";
import { BaseFormGroup } from "./BaseFormGroup";
import { FileActions } from "./FileActions";
import lang from '../Localization';
import {validate} from '../Models/ItemIdentifier';

import '../Styles/FileInfo.css';

class AudioFileInfo extends React.Component {
    constructor(props) {
        super(props);

        if(props.file.shown === undefined) props.file.shown = true; // Default to showing everything
        if(props.file.valid === undefined) props.file.valid = true;
        if(props.file.dirty === undefined) props.file.dirty = false; // This is used to only show errors after the user has had the chance to fill in something

        this.state = {
            authorEdit: {},
        }
    }

    getEdit = (key) => {
        return {setState: obj => this.setState({[key]: Object.assign({}, obj)}), state: this.state[key]};
    }

    setValue = (field, value) => {
        this.props.file[field] = value;

        if(field === "identifier") {
            this.props.file.valid = validate(value, "isrc");
        }

        this.props.appFunctions.itemUpdated(this.props.item);
    }

    setDirty = () => {
        this.props.file.dirty = true;
        this.props.appFunctions.itemUpdated(this.props.item);
    }
    
    render() {
        const authorEdit = this.getEdit("authorEdit");

        const authors = this.props.file.authors.map((a) => {
            return <AddedAuthordisplay 
                allowCopy={true} 
                key={a.id} author={a} 
                item={this.props.item} 
                file={this.props.file} 
                appFunctions={this.props.appFunctions} 
                authorEdit={authorEdit} 
            />
        });

        const showError = this.props.file.identifier && !this.props.file.valid && this.props.file.dirty;

        return (
            <div className="file-info">
                <div className="file-action-bar">
                    {this.props.file.originalFilename}
                    <FileActions item={this.props.item} file={this.props.file} appFunctions={this.props.appFunctions} />
                </div>
                <div style={this.props.file.shown ? {} : {display: "none"}}>
                    <Row>
                        <Col lg>
                            <BaseFormGroup required={false} helpText={lang.helpTexts.audioFileTitle} labelText={lang.audioFileTitle} field={"audioFileTitle"} value={this.props.file.title} onValueChange={ (v) => this.setValue("title", v) } />
                        </Col>
                        <Col lg>
                            <BaseFormGroup required={false} showErrorClass={showError} helpText={lang.helpTexts.audioFileIdentifier} labelText={lang.audioFileIdentifier} field={"audioFileIdentifier"} value={this.props.file.identifier} onValueChange={ (v) => this.setValue("identifier", v) } onBlur={this.setDirty} />
                            { showError ? <div style={{marginTop: "-15px", marginBottom: "15px", color: "#ff0000"}}>{lang.errors.identifiers.isrc}</div> : null}
                        </Col>
                    </Row>
                    <div className="file-author-info">
                        <h3>{lang.authors}</h3>
                        <AddAuthorField 
                            appFunctions={this.props.appFunctions} 
                            file={this.props.file} item={this.props.item} 
                            authorEdit={authorEdit} 
                            roles={["artist", "arranger", "lyricist", "composer"]} 
                        />
                        {authors}
                    </div>
                </div>
            </div>
        );
    }
  }

  export { AudioFileInfo }