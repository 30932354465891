import React from "react"

class LoadingBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: 0,
            total: 0,
            currentProgress: 0
        }

        this.props.backend.setUploadStatusListener((status) => {
            this.setState(status)
        })
    }

    render() {
        let percent = 0;
        if(this.state.total > 0) percent = this.state.sent / this.state.total;
        
        return (
            <div>
                <div className="loading-bar" style={{width: (percent * 100) + "%" }}></div>
                <div className="loading-bar" style={{width: (this.state.currentProgress * 100) + "%" }}></div>
            </div>
        )
    }
  }

  export { LoadingBar }