import React from "react"

import {BaseFormGroup} from './BaseFormGroup';

import lang from '../Localization';

class ItemFormGroup extends React.Component {
    setValue = (value) => {
        this.props.appFunctions.setItemField(this.props.item.id, this.props.field, value);
    }
  
    render() {
        const labelTextKey = this.props.labelTextKey ? this.props.labelTextKey : this.props.field;
        const helpTextKey = this.props.helpTextKey ? this.props.helpTextKey : this.props.field;
        const maxLength = this.props.maxLength || 255; // Default max length for each field is 255 chars
        return (
            <BaseFormGroup
                labelText={lang[labelTextKey]}
                helpText={lang.helpTexts[helpTextKey]}
                value={this.props.item[this.props.field]}
                onValueChange={this.setValue}
                maxLength={maxLength}
                {...this.props} />
        );
    }
  }

  export { ItemFormGroup }