import React from "react"

import {Button, InputGroup} from 'react-bootstrap';
import { BsFillExclamationCircleFill } from "react-icons/bs";

import loading from '../../images/loading.gif';

class ItemSelectButton extends React.Component {
    delete = () => {
        this.props.appFunctions.deleteItem(this.props.item);
    }

    select = () => {
        this.props.appFunctions.selectItem(this.props.item);
    }
  
    render() {
        const errorDisplay = this.props.forceShowErrors && !this.props.item.isValid() ? <span className="item-has-errors-display"><BsFillExclamationCircleFill /></span> : false;
        const textClass = this.props.selectedItem.id === this.props.item.id ? "bold-text item-select-button-text" : "item-select-button-text";
        return (
            <div className="item-select-button">
                <InputGroup>
                    <InputGroup.Prepend className="item-select-button-clickable" onClick={ this.select }>
                        <InputGroup.Text className={textClass}>
                            {this.props.item.loading ? <img src={loading} className="item-select-loading-indicator" alt="" /> : ""}
                            {errorDisplay}
                            {this.props.item.toString()}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                        <Button variant={"danger"} onClick={ this.delete }>
                            x
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
  }

  export { ItemSelectButton }