import React from "react";
import {Row, Col} from "react-bootstrap";

import { AddedAuthordisplay } from "./AddedAuthorDisplay";
import { AddAuthorField } from "./AddAuthorField";
import { BaseFormGroup } from "./BaseFormGroup";
import { FileActions } from "./FileActions";
import lang from '../Localization';

import '../Styles/FileInfo.css';

class NotesFileInfo extends React.Component {
    constructor(props) {
        super(props);

        if(props.file.shown === undefined) props.file.shown = true; // Default to showing everything

        this.state = {
            authorEdit: {},
        }
    }

    getEdit = (key) => {
        return {setState: obj => this.setState({[key]: Object.assign({}, obj)}), state: this.state[key]};
    }

    setValue = (field, value) => {
        this.props.file[field] = value;
        this.props.appFunctions.itemUpdated(this.props.item);
    }
    
    render() {
        const authorEdit = this.getEdit("authorEdit");

        const authors = this.props.file.authors.map((a) => {
            return <AddedAuthordisplay 
                allowCopy={true} 
                key={a.id} 
                author={a} 
                item={this.props.item} 
                file={this.props.file} 
                appFunctions={this.props.appFunctions} 
                authorEdit={authorEdit} 
            />
        });

        return (
            <div className="file-info">
                <div className="file-action-bar">
                    {this.props.file.originalFilename}
                    <FileActions item={this.props.item} file={this.props.file} appFunctions={this.props.appFunctions} />
                </div>
                <div style={this.props.file.shown ? {} : {display: "none"}}>
                    <Row>
                        <Col>
                            <BaseFormGroup required={false} helpText={lang.helpTexts.noteFileTitle} labelText={lang.fileTitle} field={"noteFileTitle"} value={this.props.file.title} onValueChange={ (v) => this.setValue("title", v) } />
                        </Col>
                    </Row>
                    <div className="file-author-info">
                        <h3>{lang.authors}</h3>
                        <AddAuthorField 
                            appFunctions={this.props.appFunctions} 
                            file={this.props.file} 
                            item={this.props.item} 
                            authorEdit={authorEdit} 
                            roles={["artist", "arranger", "lyricist", "composer"]} />
                        {authors}
                    </div>
                </div>
            </div>
        );
    }
  }

  export { NotesFileInfo }