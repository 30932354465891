import React from "react";

import {InputGroup, Button} from 'react-bootstrap';
import {BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";

class FileActions extends React.Component {
    constructor(props) {
        super(props);

        if(props.file.shown === undefined) props.file.shown = true; // Default to showing everything
    }

    removeFile = () => {
        this.props.item.deleteFile(this.props.file);
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    toggleShown = () => {
        this.props.file.shown = !this.props.file.shown;
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    render() {
        return (
            <div style={{float:"right"}}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <Button onClick={this.toggleShown} className="hide-file-button">
                            { this.props.file.shown ? <BsFillEyeSlashFill /> : <BsFillEyeFill /> }
                        </Button>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                        <Button variant="danger" onClick={this.removeFile}>
                            X
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
  }

  export { FileActions }