import React from "react";

import '../Styles/Attachments.css';

class AttachmentRemoveButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseHover: false
        };
    }

    removeAttachment = () => {
        this.props.item.deleteFile(this.props.file);
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    mouseEnter = () => {
        this.setState({mouseHover: true});
    }
    mouseLeave = () => {
        this.setState({mouseHover: false});
    }
  
    render() {
        return (
            <div className="attachment-remove-button" onClick={this.removeAttachment} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                {this.props.file.originalFilename}
                {this.state.mouseHover ? <span className="attachment-remove-button-addition">x</span> : ""}
            </div> 
        );
    }
  }

  export { AttachmentRemoveButton }