import React from "react";
import { Alert } from "react-bootstrap";
import { ItemFormGroup } from '../FormElements/ItemFormGroup';
import { IdentifierFormGroup } from '../FormElements/IdentifierFormGroup';
import { GeneralFields } from './GeneralFields';
import { AddAuthorField } from "../FormElements/AddAuthorField";
import { AddedAuthordisplay } from "../FormElements/AddedAuthorDisplay";
import { LanguageSelect } from "../FormElements/LanguageSelect";
import { SeriesInfo } from "../FormElements/SeriesInfo";
import { ThesisInfo } from "../FormElements/ThesisInfo";
import { UrlField } from "../FormElements/UrlField";

import { Row, Col } from 'react-bootstrap';
import lang from '../Localization';

class BookView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isbnInfoClosed: false,
            showIsbnInfo: false,
            authorEdit: {},
        }
    }

    getEdit = (key) => {
        return {setState: obj => this.setState({[key]: Object.assign({}, obj)}), state: this.state[key]};
    }

    hideIsbnInfo = () => {
        this.setState({isbnInfoClosed: true, showIsbnInfo: false});
    }

    setField = (id, field, val) => {
        if(field === "isbn") {
            this.setState({showIsbnInfo: true});
        }

        this.props.appFunctions.setItemField(id, field, val);
    }

    render() {
        let fileList = this.props.item.getFiles("main").map((f) => {
            return <li key={f.tmpFilename}>{f.originalFilename}</li>
        });

        const authorEdit = this.getEdit("authorEdit");

        // Books only ever have a single main file, so all authors are related to that
        let authors = this.props.item.getFiles("main")[0].authors.map((a) => {
            return <AddedAuthordisplay 
                key={a.id} 
                author={a} 
                item={this.props.item} 
                file={this.props.item.getFiles("main")[0]} 
                appFunctions={this.props.appFunctions} 
                authorEdit={authorEdit} 
            />
        });

        return (
            <div>
                <Row>
                    <Col md>
                        <h3>{lang.basicInformation}</h3>
                        <div>
                            <span style={{color:"red"}}>*</span> = {lang.fieldRequired}
                        </div>
                        <IdentifierFormGroup identifier={"isbn"} needsToBeValid={true} {...this.props}/>
                        <div style={ this.state.showIsbnInfo && !this.state.isbnInfoClosed ? {} : {display: "none"} }>
                            <Alert variant="success" style={{marginTop: "-10px"}} onClose={this.hideIsbnInfo} dismissible>
                                <div className="small">
                                    {lang.isbnSearchInfoLong}
                                </div>
                            </Alert>
                        </div>
                        <ItemFormGroup field={"printIsbn"} {...this.props} />
                        <ItemFormGroup required={true} field={"title"} {...this.props} />
                        <ItemFormGroup field={"subtitle"} {...this.props} />
                        <ItemFormGroup required={true} field={"publisher"} {...this.props} />
                        <ItemFormGroup field={"placeOfPublication"} {...this.props} />
                        <ItemFormGroup field={"yearOfPublication"} {...this.props} />
                        <LanguageSelect {...this.props} />
                    </Col>
                    <Col md>
                        <div>
                            <h3>{lang.files}</h3>
                            <ul>
                                {fileList}
                            </ul>

                            {lang.bookFilesInfo}
                        </div>

                        <div>
                            <h3>{lang.authors}</h3>
                            <AddAuthorField 
                                appFunctions={this.props.appFunctions} 
                                file={this.props.item.getFiles("main")[0]} 
                                item={this.props.item} 
                                authorEdit={authorEdit} 
                                roles={["writer", "editor", "illustrator", "translator"]} 
                            />
                            {authors}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UrlField {...this.props} />
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <SeriesInfo item={this.props.item} appFunctions={this.props.appFunctions} />
                    </Col>
                    <Col md>
                        <ThesisInfo item={this.props.item} appFunctions={this.props.appFunctions} />
                    </Col>
                </Row>

                <GeneralFields item={this.props.item} appFunctions={this.props.appFunctions} />
            </div>
        );
    }
  }

  export { BookView }