import React from "react"
import ReactTooltip from "react-tooltip";

import {Button, FormGroup, FormControl, InputGroup, FormText, Alert} from 'react-bootstrap';
import { BsCheck, BsFillQuestionCircleFill } from "react-icons/bs";
import lang from '../Localization';
import {validate} from '../Models/ItemIdentifier';

import loading from '../images/loading.gif';

class IdentifierFormGroup extends React.Component {
    constructor(props) {
        super(props);
        
        if(!this.props.item.fields[this.props.identifier]) this.props.item.fields[this.props.identifier] = {};

        this.state = {
            fieldDirty: this.props.item.fields[this.props.identifier].dirty || false,
            isValid: this.props.item[this.props.identifier] && validate(this.props.item[this.props.identifier], this.props.identifier),
            infoClosed: false,
            showInfo: false
        }
    }
    findInfo = async () => {
        if(!this.props.item[this.props.identifier] || !this.state.isValid) return;

        this.props.item.fields[this.props.identifier].loading = true;
        this.props.item.fields[this.props.identifier].done = false;
        this.props.appFunctions.itemUpdated(this.props.item);

        await this.props.appFunctions.getMetadataForItem(this.props.item, this.props.identifier, this.props.item[this.props.identifier]);
        
        this.props.item.fields[this.props.identifier].loading = false;
        this.props.item.fields[this.props.identifier].done = true;
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    componentDidUpdate(prevProps) {
        if(!this.props.item.fields[this.props.identifier]) this.props.item.fields[this.props.identifier] = {};

        // Props changed, set the state again
        if(this.props.item.fields[this.props.identifier].dirty !== prevProps.item.fields[this.props.identifier].dirty) {
            this.setState({
                fieldDirty: this.props.item.fields[this.props.identifier].dirty || false,
            });
        }
        if(this.props.item[this.props.identifier] !== prevProps.item[this.props.identifier]) {
            this.setState({
                isValid: this.props.item[this.props.identifier] && validate(this.props.item[this.props.identifier], this.props.identifier)
            });
        }
    }

    setValue = (ev) => {
        const target = ev.target;
        const value = target.value;
        const name = target.name;

        this.props.item.fields[this.props.identifier].done = false; // Identifier changed, so no longer show the loading or checkmark
        this.props.item.fields[this.props.identifier].loading = false;

        this.setState({isValid: validate(value, this.props.identifier)});

        this.setState({showInfo: true});
        this.props.appFunctions.setItemField(this.props.item.id, name, value);
    }

    hideInfo = () => {
        this.setState({infoClosed: true, showInfo: false});
    }

    setDirty = () => {
        this.setState({fieldDirty: true});
        // Also save the info to the item
        this.props.item.fields[this.props.identifier].dirty = true;
    }
  
    render() {
        // Quick fix to a bug where this might not always be set if uploading more than one item at a time
        if(!this.props.item.fields[this.props.identifier]) this.props.item.fields[this.props.identifier] = {};

        const helpText = this.props.helpTextKey ? lang.helpTexts[this.props.helpTextKey] : lang.helpTexts[this.props.identifier];
        const showError = this.props.needsToBeValid && (this.props.forceShowErrors || this.state.fieldDirty) && (this.props.item[this.props.identifier] && !this.state.isValid);
        
        const buttonContent = this.props.item.fields[this.props.identifier].loading ? <img style={{width:"20px"}} src={loading} alt="" /> : ( this.props.item.fields[this.props.identifier].done ? <BsCheck /> : lang.search );
        
        const searchInfoShort = this.props.helpTextKey ? lang.searchInfo.short[this.props.helpTextKey] : lang.searchInfo.short[this.props.identifier];
        const searchInfoLong = this.props.helpTextKey ? lang.searchInfo.long[this.props.helpTextKey] : lang.searchInfo.long[this.props.identifier];

        return (
            <div>
                <FormGroup>
                    <ReactTooltip effect="solid" />
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-text-form">{lang[this.props.identifier]}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            name={this.props.identifier}
                            aria-label={lang[this.props.identifier]}
                            value={this.props.item[this.props.identifier]}
                            onChange={this.setValue}
                            onBlur={this.setDirty}
                            className={showError ? "input-error" : ""}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-group-text-form" data-tip={helpText}>
                                <BsFillQuestionCircleFill />
                            </InputGroup.Text>
                        </InputGroup.Append>
                        <InputGroup.Append>
                            <Button title={searchInfoShort} style={{width: "80px"}} variant="success" onClick={ this.findInfo }>{buttonContent}</Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <FormText style={ showError ? {marginTop: "-15px", color: "#ff0000"} : {display: "none"}}>
                        {lang.errors.identifiers[this.props.identifier]}
                    </FormText>
                </FormGroup>
                <div style={ searchInfoLong && (this.state.showInfo && !this.state.infoClosed) ? {} : {display: "none"} }>
                    <Alert variant="success" style={{marginTop: "-10px"}} onClose={this.hideInfo} dismissible>
                        <div className="small">
                            {searchInfoLong}
                        </div>
                    </Alert>
                </div>
            </div>
        );
    }
  }

  export { IdentifierFormGroup }