import React from "react"
import {Button} from "react-bootstrap";
import lang from '../Localization';

class SuccessPage extends React.Component {
    reset = () => {
        this.props.appFunctions.reset();
    }

    render() {
        return (
            <div style={{textAlign: "center"}}>
                <h3>{lang.success.thanks}</h3>
                <Button onClick={this.reset}>{lang.success.link}</Button>
            </div>
        );
    }
  }

  export { SuccessPage }