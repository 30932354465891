import React from "react"
import { ItemFormGroup } from '../FormElements/ItemFormGroup'
import { IdentifierFormGroup } from "../FormElements/IdentifierFormGroup";
import { GeneralFields } from './GeneralFields';

import { Row, Col } from 'react-bootstrap'
import lang from '../Localization';
import { AudioFileInfo } from "../FormElements/AudioFileInfo";
import { AddFilesButton } from '../FormElements/AddFilesButton';
import { LanguageSelect } from "../FormElements/LanguageSelect";
import { UrlField } from "../FormElements/UrlField";

class AudioView extends React.Component {
    render() {
        const audioFiles = this.props.item.getFiles("main").map((f) => <AudioFileInfo appFunctions={this.props.appFunctions} key={f.tmpFilename} item={this.props.item} file={f} />)

        return (
            <div>
                <Row>
                    <Col md>
                        <h3>{lang.basicInformation}</h3>
                        <div>
                            <span style={{color:"red"}}>*</span> = {lang.fieldRequired}
                        </div>
                        <IdentifierFormGroup identifier={"identifier"} helpTextKey={"albumIdentifier"} {...this.props} />
                        <ItemFormGroup required={true} field={"title"} labelTextKey={"albumTitle"} helpTextKey={"albumTitle"} {...this.props} />
                        <ItemFormGroup field={"artist"} {...this.props} />
                        <ItemFormGroup required={true} field={"publisher"} labelTextKey={"publisher_alt"} {...this.props} />
                        <ItemFormGroup field={"placeOfPublication"} {...this.props} />
                        <ItemFormGroup field={"yearOfPublication"} {...this.props} />
                        <LanguageSelect {...this.props} additionalOptions={ [{ text: lang.languages.Instrumental, value: "inst" }] } />
                    </Col>
                    <Col md>
                        <h3>{lang.songs}</h3>
                        <div style={{marginBottom: "10px"}}>
                            {lang.audioFilesInfo}
                        </div>
                        <div>
                            {audioFiles}
                        </div>
                        <div>
                            <AddFilesButton message={lang.addAudioFile} item={this.props.item} appFunctions={this.props.appFunctions} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UrlField {...this.props} />
                    </Col>
                </Row>

                <GeneralFields item={this.props.item} appFunctions={this.props.appFunctions} />
            </div>
        );
    }
  }

  export { AudioView }