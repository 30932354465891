import React from "react";
import axios from "axios";

import {FormControl, InputGroup, Button} from 'react-bootstrap';
import { BsFillPlusCircleFill, BsCheck } from "react-icons/bs";
import { KeywordRemoveButton } from './KeywordRemoveButton';
import lang from '../Localization';

import '../Styles/Keywords.css';

class KeywordField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            foundWords: []
        };
    }

    setValue = async (ev) => {
        let word = ev.target.value;
        this.setState({searchString: word});
        
        let currentLang = lang.getLanguage();
        if(currentLang === "en") currentLang = "fi";
        const fintoURL = 'https://api.finto.fi/rest/v1/search?vocab=yso yso-paikat&lang=' + currentLang +'&query=' + ev.target.value + "*";
        let resp = await axios.get(fintoURL);
        // If at this point the word we were searching is not the latest search string (the user has typed more probably) just discard this search
        if(word !== this.state.searchString) return;

        // Multiple words may have the same preferred label, so we filter out duplicates
        let words = resp.data.results.filter((w, index, arr) => {
            let alreadyAdded = arr.map(i => i.prefLabel).indexOf(w.prefLabel) !== index;
            return !alreadyAdded;
        });
        
        this.setState({foundWords: words});
    }

    clearSearch = () => {
        this.setState({ searchString: "", foundWords: [] });
    }

    addKeyword = (keyword) => {
        if(this.props.item.keywords.indexOf(keyword) !== -1) return; // No duplicate words please
        let kws = this.props.item.keywords.concat(keyword);
        this.props.appFunctions.setItemField(this.props.item.id, "keywords", kws);
    }
    removeKeyword = (keyword) => {
        let kws = this.props.item.keywords.filter((w) => w !== keyword);
        this.props.appFunctions.setItemField(this.props.item.id, "keywords", kws);
    }
  
    render() {
        let foundWords = this.state.foundWords.map((w) => 
            {   
                if(this.props.item.keywords.indexOf(w.prefLabel) !== -1) {
                    return <div className="keyword-display" key={w.prefLabel} onClick={ () => this.removeKeyword(w.prefLabel)}>
                        <BsCheck />
                        <span className="keyword-label-display">{w.prefLabel}</span>
                    </div>
                }
                
                return <div className="keyword-display" key={w.prefLabel} onClick={ () => this.addKeyword(w.prefLabel) }>
                    <BsFillPlusCircleFill />
                    <span style={{marginLeft: 5}}>{w.prefLabel}</span>
                </div>
            }
        );
        let addedWords = this.props.item.keywords.map((w) => { 
            return <KeywordRemoveButton word={w} removeKeyword={this.removeKeyword} item={this.props.item} key={w} />
        });
        return (
            <div>
                <h3>{lang.keywords}</h3>
                <div>
                    {lang.keywordInfo} <a href={lang.fintoURL} target="_blank" rel="noreferrer">{lang.ysoForKeywordInfo}</a>. {lang.keywordInfoForSerials}
                </div>
                <InputGroup className="mb-3">
                    <FormControl
                        name="keyword-search"
                        onChange={this.setValue}
                        value={this.state.searchString}
                        placeholder={lang.startWritingKeyword}
                    />
                    <InputGroup.Append>
                        <Button className="input-group-text-form" onClick={this.clearSearch}>
                            {lang.clear}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                <div>
                    {foundWords}
                </div>
                <div style={ this.props.item.keywords.length > 0 ? {} : {display: "none"} }>
                    {lang.addedKeywords}
                    <div>
                        {addedWords}
                    </div>
                </div>
            </div>
        );
    }
  }

  export { KeywordField }