const isbnChecksum = (arr, weights) => {
    return arr
        .reduce((a, x, i) => {
            a.push([Number(x), weights[i]]);
            return a;
        }, [])
        .reduce((sum, a) => {
            return sum + a[0] * a[1];
        }, 0);
};

const validateIsbn10 = (isbn) => {
    const weights = [10,9,8,7,6,5,4,3,2,1];
    isbn = isbn.split("");
    if(isbn[isbn.length-1] === "x" || isbn[isbn.length-1] === "X") isbn[isbn.length-1] = 10;
    return isbnChecksum(isbn, weights) % 11 === 0;
}

const validateIsbn13 = (isbn) => {
    const weights = [1,3,1,3,1,3,1,3,1,3,1,3,1];
    return isbnChecksum(isbn.split(""), weights) % 10 === 0;
}

const validateIsbn = (isbn) => {
    const ALLOWEDCHARS = /^[ \-xX]*$/;
    if (! ALLOWEDCHARS.test(isbn.replace(/\d/g, ''))) {
        return false;
    }

    isbn = isbn.replace(/-/g, '');

    if(isbn.length === 10) return validateIsbn10(isbn);
    else if(isbn.length === 13) return validateIsbn13(isbn);

    return false;
}

const validateIssn = (issn) => {
    var ISSNWEIGHTS = [8, 7, 6, 5, 4, 3, 2];
    var parts = issn.split('-');
    if(parts.length !== 2) {
        return false;
    }
    
    if(parts[0].length !== 4 || parts[1].length !== 4) {
        return false;
    }
    
    // Got a good looking issn. Now go through the digits (checking they are actually digits) except the last one, calculate the checksum and compare with the last digit
    
    // Add all digits into one array
    var digits = [];
    digits = digits.concat(parts[0].split(''));
    digits = digits.concat(parts[1].split(''));
    
    // Calculate sum of all digits except last
    var sum = 0;
    var control = digits[digits.length - 1];
    for(var i = 0; i < digits.length -1; i++) {
        var digitInt = parseInt(digits[i]);
        sum += (digitInt * ISSNWEIGHTS[i]);
    }
    
    if(isNaN(sum)) {
        return false;
    }
    
    sum %= 11;

    // If the remainder is not zero, it needs to be deducted from 11
    if (sum !== 0)
        sum = 11 - sum;
    
    if(sum < 10) {
        control = parseInt(control);
        if(sum !== control) {
            return false;
        }
    }
    else {
        if(control !== 'x' && control !== 'X') { // X is used to represent 10 in the control digit
            return false;
        }
    }
    
    // If we get here, it's valid!
    return true;
}

const validateIsmn = (ismn) => {
    // This is basically the same as validating an ISBN13
    const ALLOWEDCHARS = /^[ \-xX]*$/;
    if (! ALLOWEDCHARS.test(ismn.replace(/\d/g, ''))) {
        return false;
    }

    ismn = ismn.replace(/-/g, '');

    return validateIsbn13(ismn);
}

const validateIsrc = (isrc) => {
    const ALLOWEDCHARS = /^[a-zA-Z-]*$/;
    if (! ALLOWEDCHARS.test(isrc.replace(/\d/g, ''))) {
        return false;
    }
    isrc = isrc.replace(/-/g, '');

    if(isrc.length !== 12) return false;

    let country = isrc.substring(0, 2);
    let registrant = isrc.substring(2, 5);
    let year = isrc.substring(5, 7);
    let id = isrc.substring(7, 12);

    if (! /^[a-zA-Z-]*$/.test(country)) return false;
    if (! /^[0-9a-zA-Z]*$/.test(registrant)) return false;
    if(! /^[0-9]*$/.test(year)) return false;
    if(! /^[0-9]*$/.test(id)) return false;

    return true;
}

export const validate = (identifier, identifierType) => {
    if(identifierType === "isbn") {
        return validateIsbn(identifier);
    }
    else if(identifierType === "issn") {
        return validateIssn(identifier);
    }
    else if(identifierType === "ismn") {
        return validateIsmn(identifier);
    }
    else if(identifierType === "isrc") {
        return validateIsrc(identifier);
    }

    return true; // Not an identifier we need to validate
}