import React from "react"
import {Button} from "react-bootstrap";
import lang from '../Localization';

class ConfirmationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alreadySent: false
        }
    }
    prev = () => {
        this.props.appFunctions.selectPage("contact");
    }

    send = () => {
        if(this.state.alreadySent) return;

        this.setState({alreadySent: true});
        this.props.appFunctions.sendForm();
    }
  
    render() {
        const sendButtonStyle = { width: "200px", height: "80px", marginLeft: "auto", marginRight: "auto", display: "block" }
        const firstColumnStyle = { verticalAlign: "top", width: "190px" };
        const titles = this.props.items.map((i) => <div key={i.id}>{i.title}</div>);
        return (
            <div>
                <h3>{lang.givenInformation}</h3>
                {lang.checkInformation}
                <table style={{width:"100%"}}>
                    <tbody>
                        <tr>
                            <td style={firstColumnStyle}><strong>{lang.contactPerson}</strong></td>
                            <td>{this.props.contactPerson.person}</td>
                        </tr>
                        <tr>
                            <td style={firstColumnStyle}><strong>{lang.email}</strong></td>
                            <td>{this.props.contactPerson.email}</td>
                        </tr>
                        <tr>
                            <td style={firstColumnStyle}><strong>{lang.phoneNumber}</strong></td>
                            <td>{this.props.contactPerson.phone}</td>
                        </tr>
                        <tr>
                            <td style={firstColumnStyle}><strong>{lang.organization}</strong></td>
                            <td>{this.props.contactPerson.organization}</td>
                        </tr>

                        <tr>
                            <td>
                                <hr/>
                            </td>
                        </tr>

                        <tr>
                            <td style={firstColumnStyle}><strong>{lang.submittedPublications}</strong></td>
                            <td>{titles}</td>
                        </tr>
                    </tbody>
                </table>
                <Button className="page-change-button" onClick={ () => {this.prev()} }>{lang.previous}</Button>

                <Button disabled={this.state.alreadySent} variant="success" style={sendButtonStyle} onClick={ () => {this.send()} }>{lang.sendForm}</Button>
            </div>
        );
    }
  }

  export { ConfirmationPage }