import React from "react";

import loading from '../images/loading.gif';

class LoadingView extends React.Component {
    render() {
        return (
            <div>
                <img src={loading} alt="" />
            </div>
        );
    }
  }

  export { LoadingView }