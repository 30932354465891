import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
    fi: {
        "mainTitle": "Elektronisten vapaakappaleiden luovutus",
        "welcome": "Elektronisten vapaakappaleiden luovutus",
        "contact": "Ota yhteyttä",
        "nationalLibrary": "Kansalliskirjasto",
        "legalDepositOffice": "Yhteystiedot",
        "legalDepositWebsite": "https://www.kansalliskirjasto.fi/fi/vapaakappaletoimisto#yhteystiedot-ja-toimitusohjeet",
        "legalDepositAddress1": "PL 15",
        "legalDepositAddress2": "00014 Helsingin yliopisto",
        "legalDepositContactForm": "Palautelomake",
        "legalDepositContactFormURL": "#contact",
        "legalDepositEmail": "sposti: vapaakappale(at)helsinki.fi",
        "legalDepositPhone": "puh: 02941 44321 neuvonta arkisin klo 9–12",
        
        "infoFooter": "Luovutuslomake on uudistunut! Voit antaa meille palautetta soittamalla arkisin klo 9-12 numeroon 02941 44321, lähettämällä sähköpostia osoitteeseen vapaakappale(at)helsinki.fi tai täyttämällä ",
        "infoFooterContactLink": "palautelomakkeen",
        "infoFooterContactLinkURL": "https://luovutuslomake.kansalliskirjasto.fi/feedback.html?lang=fi",
    
        "infoText": "Aloita valitsemalla luovutettava julkaisutyyppi vasemmalta.",
        "infoTextMultiple": "Voit samalla kertaa luovuttaa useita julkaisuja/julkaisutyyppejä.",
    
        "savedItemsInfo": "Hei! Havaitsimme että olet aiemmin jättänyt lomakkeen täyttämisen kesken ja olemme palauttaneet viimeksi täyttämäsi tiedot. Mikäli haluat poistaa aiemmin liittämäsi tiedostot, voit painaa vasemmalta niiden nimen perässä näkyvää rastia. Nämä tiedot on tallennettu oman tietokoneesi selaimeen, eikä niitä säilytetä missään muualla.",
    
        "submitterInfo": "Yhteystiedot",
        "contactPerson": "Yhteyshenkilö",
        "fieldRequired": "Kenttä on pakollinen",
        "otherFieldRequired": "Toinen kentistä (sähköposti tai puhelin) on pakollinen",
        "email": "Sähköposti",
        "phoneNumber": "Puhelinnumero",
        "organization": "Organisaatio",
        
        "privacyNotice": {
            "title": "Tietosuojaseloste",
            "content": [
              "Antamiasi henkilötietoja käytetään vapaakappaleiden luovutusvelvollisuuden täyttämisen todentamiseen ja seurantaan sekä tarvittaessa aineistojen luovutukseen liittyvään yhteydenottoon. Saat sähköpostitse kuittauksen luovutuksesta, joka toimii myös todisteena aineistojen luovutusvelvollisuuden täyttämisestä, eli tarkistathan antamiesi tietojen oikeellisuuden.",
              "Pääsy tietoihin on valvottu ja rajattu vain vapaakappalepalveluiden työntekijöille. Emme luovuta tietojasi eteenpäin emmekä käytä niitä muihin tarkoituksiin. Tiedot säilytetään pysyvästi."
            ]
        },
        
        "submission": "Luovutus",
        "file": "Tiedosto",
        "attachments": "Liitteet",
        "attachmentsInfo": "Jos julkaisulla on liitteitä (kuten kansikuva tai erillinen metatietotiedosto), voit liittää ne tähän.",
        "publicationsToSubmit": "Luovutettavat julkaisut",
        "publishingInfo": "Kustantajan tiedot",
        "copy": "Kopioi",
        "paste": "Liitä",
        "hide": "Piilota",
        "show": "Näytä",
        "edit": "Muokkaa",
        "remove": "Poista",
        "removeFile": "Poista tiedosto",
        "book": "kirja",
        "notes": "nuotti",
        "audio": "musiikkiäänite",
        "serial": "lehti",
        "other": "muu",
        "basicInformation": "Julkaisun perustiedot",
        "identifier": "Tunniste",
        "isbn": "ISBN",
        "ismn": "ISMN",
        "title": "Nimeke",
        "artist": "Esittäjä",
        "subtitle": "Alanimeke",
        "publisher": "Kustantaja",
        "publisher_alt": "Julkaisija",
        "publisher_and_alt": "Kustantaja / Julkaisija",
        "placeOfPublication": "Kustannuspaikka",
        "yearOfPublication": "Julkaisuvuosi",
        "yearOfPublicationForSerial": "Julkaisuvuodet",
        "language": "Kieli",
        "search": "Hae",
        "searchInfo": {
          "short": {
            "isbn": "Etsi teoksen tiedot ISBN:n perusteella",
            "issn": "Etsi sarjan tiedot ISSN:n perusteella",
            "serialISSN": "Etsi sarjan tiedot ISSN:n perusteella",
            "ismn": "Etsi nuottivihon tiedot sen ISMN:n perusteella",
            "albumIdentifier": "Etsi albumin tiedot sen tunnuksen perusteella"
          },
          "long": {
            "isbn": "Huom! Klikattuasi 'Hae' lomake tarkistaa löytyykö antamallasi ISBN:llä teoksen tietoja tietokannastamme ja täyttää löydetyt kentät automaattisesti.",
            "serialISSN": "Huom! Klikattuasi 'Hae' lomake tarkistaa löytyykö antamallasi ISSN:llä teoksen tietoja tietokannastamme ja täyttää löydetyt kentät automaattisesti.",
            "ismn": "Huom! Klikattuasi 'Hae' lomake tarkistaa löytyykö antamallasi ISMN:llä teoksen tietoja tietokannastamme ja täyttää löydetyt kentät automaattisesti.",
            "albumIdentifier": "Huom! Klikattuasi 'Hae' lomake tarkistaa löytyykö antamallasi tunnisteella teoksen tietoja tietokannastamme ja täyttää löydetyt kentät automaattisesti."
          }
        },
        
        "keywords": "Asiasanat",
        "keywordInfo": "Asiasanojen tulee löytyä",
        "ysoForKeywordInfo": "Yleisestä suomalaisesta ontologiasta",
        "keywordInfoForSerials": "Lehtien kohdalla asiasanat koskevat koko nimekettä, eivät yksittäisiä numeroita.",
        "fintoURL": "https://finto.fi/yso/fi/",
    
    
    
        "addKeyword": "Lisää asiasana",
        "addedKeywords": "Lisätyt asiasanat",
        "startWritingKeyword": "Ala kirjoittaa asiasanaa...",
        "clear": "Tyhjennä",
        
        "authors": "Tekijät",
        "addAuthor": "Lisää tekijä",
        "editAuthor": "Muokkaa tekijää",
        "copyAuthor": "Kopioi tekijän tiedot muihin tiedostoihin",
    
        "personAuthor": "Henkilötekijä",
        "organizationAuthor": "Organisaatiotekijä",
        "authorName": "Tekijän nimi",
        "authorNameShort":  "Tekijä",
        "authorNameInfo": "Tekijän tiedot",
        "authorNameInfoShort": "Tiedot",
        "authorNameOrder": "Sukunimi, Etunimi",
        "person": "Henkilö",
        "firstName": "Etunimi",
        "lastName": "Sukunimi",
        "organizationName": "Organisaation nimi",
    
        "writer": "Kirjoittaja",
        "illustrator": "Kuvittaja",
        "translator": "Kääntäjä",
        "editor": "Toimittaja",
    
        "albumTitle": "Julkaisun nimi",
        "audioFileTitle": "Nimi",
        "audioFileIdentifier": "ISRC",
        "songs": "Kappaleet / teokset",
    
        "composer": "Säveltäjä",
        "lyricist": "Sanoittaja",
        "arranger": "Sovittaja",
        
        "seriesInfo": "Sarjan tiedot",
        "isPartOfSeries": "Julkaisu kuuluu sarjaan",
        "issn": "ISSN",
        "seriesName": "Sarjan nimi",
        "issueNumber": "Lehden numero",
        "volumeNumber": "Osan numero",
        "volumes": "Numerot",
        
        "thesisInfo": "Opinnäytteen tiedot",
        "isThesis": "Julkaisu on opinnäyte",
        "institution": "Oppilaitos",
        "thesisType": "Opinnäytteen tyyppi",
        "doctoral": "väitöskirja",
        "licentiate": "lisensiaatintyö",
        "otherThesis": "muu opinnäyte",
        "thesisPlace": "Väittelypaikkakunta",
        "thesisYear": "Väittelyvuosi",
        
        "otherInfo": "Muut tiedot",
        "url": "Sijainti verkossa",
        "urlAdditionalInfo": "{0} mukaisesti myös aineiston alkuperäinen sijaintipaikka tulee ilmoittaa.",
        "culturalMaterialActName": "Lain kulttuuriaineistojen tallettamisesta ja säilyttämisestä",
        "culturalMaterialActUrl": "https://www.finlex.fi/fi/laki/ajantasa/2007/20071433",
        "printIsbn": "Painetun version ISBN",
        "public": "Julkinen",
        "isSecret": "Salassa pidettävä",
        "reasonForSecrecy": "Lainkohta, johon salassapito perustuu",
        "additionalInfo": "Lisätiedot",
    
        "claimId": "Luovutuksen viitenumero",
        
        "startByChoosingFile": "Aloita valitsemalla tiedosto",
        "startByChoosingAudioFile": "Aloita lataamalla vähintään yksi kappale",
    
    
        "multipleFilesInfo": "Olet valinnut useamman kuin yhden tiedoston. Kuuluvatko tiedostot yksittäiseen julkaisuun (musiikkiäänitteen kappaleet, lehden numerot), vai onko kukin niistä oma julkaisunsa?",
        "multiplePublicationsAnswer": "Useita julkaisuja",
        "singlePublicationAnswer": "Yksittäinen julkaisu",
        "addFile": "Lisää tiedosto",
        "addAudioFile": "Lisää kappale",
        "addIssue": "Lisää numero",
        "addAttachment": "Lisää liite",
        "bookFilenameRecommendation": "Suosittelemme, että tiedoston nimenä käytetään julkaisun tunnusta (ISBN, ISSN); tai tunnuksen puuttuessa sen nimeä.",
        
        "bookFilesInfo": "Huom! Mikäli kirja koostuu useammasta kuin yhdestä tiedostosta, voit lisätä muut tiedostot 'Liitteet'-kohdassa",
        "audioFilesInfo": "Huom! Mikäli haluat lisätä esimerkiksi levyn kansikuvan, voit tehdä sen 'Liitteet'-kohdassa",
    
        "files": "Tiedostot",
        "fileTitle": "Tiedoston nimeke",
    
        "previous": "Edellinen",
        "next": "Jatka",
        "close": "Sulje",
        "cancel": "Peruuta",
    
        "formSending": "Lomakkeen lähetys",
        "givenInformation": "Annetut tiedot",
        "submittedPublications": "Luovutetut teokset",
        "checkInformation": "Ole hyvä ja tarkista tiedot ennen lähettämistä.",
        "sendForm": "Lähetä",
        "beforeSending": "Ole hyvä ja korjaa seuraavat virheet:",
    
        "generalHelp": {
            "title": "Lisätietoja",
            "paragraph1": "Klikattuasi haluamaasi julkaisutyyppiä, avautuvasta ikkunasta tulee etsiä luovutettava tiedosto. Tiedoston valittuasi aukenee täytettäväksesi lomake julkaisun tiedoista.",
            "paragraph2": "Blii blaa bloo klikkaile ja jep jep jep joo loorem ipsum"
        },
        
        "helpTexts": {
            "isbn": "Teoksen ISBN-tunnus. Huomaathan, että ISBN-tunnuksen tulee olla luovutettavan e-version ISBN-tunnus.",
            "printIsbn": "Mikäli teoksesta on myös painettu versio, sen ISBN.",
            "title": "Teoksen nimi/pääotsikko.",
            "subtitle": "Teoksen alaotsikko.",
            "publisher": "Julkaisun kustantamisesta, julkaisemisesta tai ilmestymisestä vastuussa olevan toimijan nimi.",
            "placeOfPublication": "Julkaisijan kotipaikka.",
            "yearOfPublication": "Vuosi jona julkaisu on ilmestynyt.",
            "yearOfPublicationForSerial": "Julkaisun ilmestymisvuodet",
            "language": "Julkaisun sisällön pääasiallinen kieli.",
            "url": "Teoksen sijainti verkossa, joka voi olla URN tai muu verkkosivuosoite. Mikäli teoksella itsellään ei ole verkkotunnusta, ilmoitetaan kustantajan tai jakelijan verkkosivuosoite.",
            "reasonForSecrecy": "Salassapitovaatimuksen on perustuttava lakiin",
            "additionalInfo": "Julkaisuun liittyviä tietoja, jotka eivät ilmene lomakkeen muista kohdista.",
            
            "albumTitle": "Nimi, jolla julkaisu tunnetaan",
            "artist": "Julkaisuun sisältyvien kappaleiden pääesittäjä",
            "audioFileTitle": "Nimi, jolla kappale tunnetaan",
            "albumIdentifier": "Julkaisun yksilöivä tunniste, kuten julkaisijan antama tuotenumero.",
            "audioFileIdentifier": "ISRC-koodi (International Standard Record Code), äänitteellä olevan kappaleen yksilöivä standarditunnus.",
            
            "ismn": "International Standard Music Number, nuottijulkaisun yksilöivä standarditunnus.",
            "noteFileTitle": "Esimerkiksi nimi, jolla tiedostoon sisältyvä kappale tunnetaan.",
            
            "serialTitle": "Julkaisun nimi",
    
            "serialISSN": "Lehden e-version ISSN-tunnus.",
    
            "issueNumber": "Lehden/osan numero, kuten julkaisussa.",
    
            "issn": "Internationational Standard Serial Number, sarjan kansainvälinen standarditunnus",
            "seriesName": "Sarjan nimeke",
            "volumeNumber": "Osan numero, kuten julkaisussa",
    
            "institution": "Opinnäytteen julkaissut oppilaitos",
            "thesisPlace": "Väitöstilaisuuden paikkakunta",
            "thesisYear": "Väitöstilaisuuden vuosi",
    
            "claimId": "Mikäli olet saanut meiltä viitenumeron, syötä se tähän"
        },
        
        "errors": {
            "errorsLeft": "Lomakkeelle lisättyjen julkaisujen tiedoissa on virheitä/puutteita.",
    
            "addAtleastOneSubmission": "Lisää vähintään yksi luovutus",
            "identifiers": {
              "isbn": "ISBN on virheellinen",
              "issn": "ISSN on virheellinen",
              "ismn": "ISMN on virheellinen",
              "isrc": "ISRC on virheellinen"
            },
            "chooseAtleastOneFile": "Ole hyvä ja valitse vähintään yksi lähetettävä tiedosto",
            "contactPersonMandatory": "Yhteyshenkilön nimi vaaditaan",
            "invalidEmail": "Sähköposti ei ole oikeassa muodossa",
            "titleMandatory": "Nimeke vaaditaan",
            "publisherMandatory": "Kustantajan tiedot vaaditaan",
            "emailOrPhoneMandatory": "Joko yhteyshenkilön puhelinnumero tai sähköpostiosoite vaaditaan",
            
            "missingInformation": "Julkaisun tiedoissa on puutteita",
    
            "unknownPublicationType": "Tuntematon julkaisutyyppi.",
            "fileTypeNotAllowed": "Julkaisu ei voi olla annettua tiedostomuotoa.",
            "allowedFileTypes": "Sallitut tiedostomuodot:",
            
            "uploadFailed": "Tiedoston lähettäminen epäonnistui. Mikäli ongelma toistuu, ole hyvä ja ota meihin yhteyttä."
        },
    
      "languages":{
        "English": "Englanti",
        "Spanish": "Espanja",
        "Sami": "Saame",
        "German": "Saksa",
        "Finnish": "Suomi",
        "French": "Ranska",
        "Swedish": "Ruotsi",
        "Russian": "Venäjä",
        "Other": "Muu tai monikielinen",
        "Instrumental": "Sanaton/Instrumentaali"
      },
    
      "success": {
        "thanks": "Kiitos luovutuksestasi Kansalliskirjastoon!",
        "link": "Lähetä uusi julkaisu"
      },
    
      "feedback": {
        "title": "Palaute uudesta luovutuslomakkeesta",
        "robot": "En ole robotti",
        "thanks": "Kiitos palautteestasi!",
        "send": "Lähetä",
        "backToForm": "Takaisin luovutuslomakkeelle",
        "info": "Mikäli haluat että sinuun ollaan yhteydessä, voit antaa sähköpostiosoitteesi"
      }
    },
    en: {
        "mainTitle": "Legal Deposit of Electronic Publications",
        "welcome": "Legal Deposit of Electronic Publications",
        "contact": "Contact information",
        "nationalLibrary": "National Library of Finland",
        "legalDepositOffice": "Contact information",
        "legalDepositWebsite": "https://www.kansalliskirjasto.fi/en/legal-deposit-office#contact-information",
        "legalDepositAddress1": "PO Box 15",
        "legalDepositAddress2": "00014 University of Helsinki",
        "legalDepositContactForm": "Feedback form",
        "legalDepositContactFormURL": "#contact",
        "legalDepositEmail": "email: vapaakappale(at)helsinki.fi",
        "legalDepositPhone": "Tel: 02941 44321 consulting weekdays 9 a.m. to 12 noon",
        
        "infoFooter": "The legal deposit web form has been updated! You can give feedback by telephone at 02941 44321 on weekdays 9 a.m. to 12 noon, by email at vapaakappale(at)helsinki.fi, or by filling out the ",
        "infoFooterContactLink": "feedback form",
        "infoFooterContactLinkURL": "https://luovutuslomake.kansalliskirjasto.fi/feedback.html?lang=en",
        "oldFormInfo": "The old form is available",
        "footerOldFormLink": "here",
    
    
        "infoText": "Start by selecting a publication type on the left.",
        "infoTextMultiple": "You can submit multiple different publications / publication types.",
    
        "savedItemsInfo": "We see that you have unsent data from a previous session. It has been added to the form. If you wish to remove the files you have previously added, you can click the remove icon beside the name of your submission in the left hand side of the screen. The information is stored in your browser, and it is not kept anywhere else.",
        
        "submitterInfo": "Contact info",
        "contactPerson": "Contact person",
        "fieldRequired": "Field is required",
        "otherFieldRequired": "At least one of the fields (email of phone) is required",
        "email": "Email",
        "phoneNumber": "Phone number",
        "organization": "Organization",
    
      "privacyNotice": {
        "title": "Privacy notice",
        "content": [
          "The personal information provided by you will be used to verify and monitor the fulfillment of the legal deposit responsibility and, when needed, for communication regarding the submission. You will receive a confirmation of submission by e-mail, which also verifies fulfillment of the legal deposit responsibility; thereby, we ask you to check that you provide your information correctly.",
          "Access to the information is monitored, and restricted to the legal deposit office staff. We will not share your information or use it for any other purpose. The information is stored permanently."
        ]
      },
    
        "submission": "Submission",
        "file": "File",
        "attachments": "Attachments",
        "attachmentsInfo": "If the publication includes attachments (such as a cover image for a book) you can add them here",
        "publicationsToSubmit": "Publications to submit",
        "publishingInfo": "Publisher information",
        "copy": "Copy",
        "paste": "Paste",
        "hide": "Hide",
        "show": "Show",
        "edit": "Edit",
        "remove": "Remove",
        "removeFile": "Remove file",
        "book": "book",
        "notes": "sheet music",
        "audio": "music recording",
        "serial": "magazine",
        "other": "other",
        "basicInformation": "Basic information about publication",
        "identifier": "Identifier",
        "isbn": "ISBN",
        "ismn": "ISMN",
        "title": "Title",
        "artist": "Artist",
        "subtitle": "Subtitle",
        "publisher": "Publisher",
        "publisher_alt": "Publisher",
        "publisher_and_alt": "Publisher",
        "placeOfPublication": "Place of publication",
        "yearOfPublication": "Year of publication",
        "yearOfPublicationForSerial": "Years of publication",
        "language": "Language",
        "search": "Search",

        "searchInfo": {
          "short": {
            "isbn": "Search publication information by ISBN",
            "issn": "Search series information by ISSN",
            "ismn": "Search music book information by ISMN",
            "albumIdentifier": "Search album information by identifier"
          },
          "long": {
            "isbn": "N.B. After clicking Seach the form will seach our databases for the ISBN number that you provided and fill out all the fields automatically in case data is found.",
            "serialISSN": "N.B. After clicking Seach the form will seach our databases for the ISSN number that you provided and fill out all the fields automatically in case data is found.",
            "ismn": "N.B. After clicking Seach the form will seach our databases for the ISMN number that you provided and fill out all the fields automatically in case data is found.",
            "albumIdentifier": "N.B. After clicking Seach the form will seach our databases for the identifier that you provided and fill out all the fields automatically in case data is found."
          }
        },
        
        "keywords": "Keywords",
        "keywordInfo": "Use only keywords from",
        "ysoForKeywordInfo": "the General Finnish Ontology YSO",
        "keywordInfoForSerials": "For magazines, keywords must apply to the title in general, not to separate volumes",
        "fintoURL": "https://finto.fi/yso/en/",
    
    
        "addKeyword": "Add keyword",
        "addedKeywords": "Keywords added",
        "startWritingKeyword": "Start typing a keyword...",
        "clear": "Clear",
        
        "authors": "Authors",
        "addAuthor": "Add author",
        "editAuthor": "Edit author",
        "copyAuthor": "Copy author's information to other files",
    
        "personAuthor": "Person as author",
        "organizationAuthor": "Organization as author",
        "authorName": "Author name",
        "authorNameShort":  "Author",
        "authorNameInfo": "Author Info",
        "authorNameInfoShort": "Info",
        "authorNameOrder": "Last name, First name",
        "person": "Person",
        "firstName": "First name",
        "lastName": "Last name",
        "organizationName": "Name of organization",
    
        "writer": "Writer",
        "illustrator": "Illustrator",
        "translator": "Translator",
        "editor": "Editor",
    
        "albumTitle": "Publication title",
        "audioFileTitle": "Song title",
        "audioFileIdentifier": "ISRC",
        "songs": "Songs / Works",
    
        "composer": "Composer",
        "lyricist": "Lyricist",
        "arranger": "Arranger",
        
        "seriesInfo": "Series info",
        "isPartOfSeries": "Publication is part of a series",
        "issn": "ISSN",
        "seriesName": "Name of series",
        "issueNumber": "Issue number",
        "volumeNumber": "Number in series",
        "volumes": "Volumes",
        
        "thesisInfo": "Thesis information",
        "isThesis": "Publication is a thesis",
        "institution": "Educational institution",
        "thesisType": "Thesis type",
        "doctoral": "doctoral dissertation",
        "licentiate": "licentiate  thesis",
        "otherThesis": "other thesis",
        "thesisPlace": "Place (city)",
        "thesisYear": "Year",
        
        "otherInfo": "Other information",
        "url": "URL of online version",
        "urlAdditionalInfo": "According to the {0} (only in Finnish) the source location of the material must be indicated.",
        "culturalMaterialActName": "Act on Collecting and Preserving Cultural Materials",
        "culturalMaterialActUrl": "https://www.finlex.fi/fi/laki/ajantasa/2007/20071433",        
        "printIsbn": "Printed version ISBN",
        "public": "Public",
        "isSecret": "To be kept secret",
        "reasonForSecrecy": "Section of law to which secrecy is based upon",
        "additionalInfo": "Additional info",
    
        "claimId": "Reference number",
        
        "startByChoosingFile": "Start by choosing a file",
        "startByChoosingAudioFile": "Start by adding at least one song",
    
    
        "multipleFilesInfo": "You have selected more than one file. Are the files part of a single publication (songs of an album, issues of a magazine, etc), or are they different publications?",
        "multiplePublicationsAnswer": "Different publications",
        "singlePublicationAnswer": "A single publication",
        "addFile": "Add file",
        "addAudioFile": "Add song",
        "addIssue": "Add issue",
        "addAttachment": "Add attachment",
        "bookFilenameRecommendation": "We recommend that the standard identifier (ISBN, ISSN) of the publication is used as filename; should the publication not have an identifier, the publication title is recommended.",
        
        "bookFilesInfo": "Note that if the book consists of more than one file, you can add the additional files as attachments below.",
        "audioFilesInfo": "Note that if you want to add e.g. a cover image of the album, you can add it as an attachment below.",

        "files": "Files",
        "fileTitle": "File title or description",
    
        "previous": "Previous",
        "next": "Continue",
        "close": "Close",
        "cancel": "Cancel",
    
        "formSending": "Sending the form",
        "givenInformation": "Information entered",
        "submittedPublications": "Publications submitted",
        "checkInformation": "Please check the information below before sending",
        "sendForm": "Send",
        "beforeSending": "Before sending",
    
        "generalHelp": {
            "title": "Additional Help",
            "paragraph1": "This text is in English: Klikattuasi haluamaasi julkaisutyyppiä, avautuvasta ikkunasta tulee etsiä luovutettava tiedosto. Tiedoston valittuasi aukenee täytettäväksesi lomake julkaisun tiedoista.",
            "paragraph2": "Blii blaa bloo klikkaile ja jep jep jep joo loorem ipsum"
        },
    
        "helpTexts": {
          "isbn": "ISBN of the publication. Please note, that the ISBN must be for the digital version submitted now",
          "printIsbn": "If there is a printed version of this work, its ISBN.",
          "title": "Main title of the work.",
          "subtitle": "Subtitle of the work",
          "publisher": "Organization or person publishing the work",
          "placeOfPublication": "Name of the city where the publisher is located.",
          "yearOfPublication": "The year when the publication was released.",
          "yearOfPublicationForSerial": "Years of the publication",
          "language": "The main language of the content of the publication.",
          "url": "A URL where the publication is available online. Can be a URN if the publication has one. If the publication doesn't have it's own URL, the publisher's or distributor's website should be given.",
          "reasonForSecrecy": "The confidentiality claim must be based on law",
          "additionalInfo": "Other information related to the publication, not given elsewhere in this form.",
    
          "albumTitle": "Name under which the publication is known",
          "artist": "Principal artist on the tracks in the publication",
          "audioFileTitle": "The name under which the track is known",
          "albumIdentifier": "Unique identification of the publication, such as product number given by the publisher.",
          "audioFileIdentifier": "ISRC-code (International Standard Record Code), a standard identifier for a track on a record.",
    
          "ismn": "International Standard Music Number, a unique standard identifier for music books.",
          "noteFileTitle": "For example the the title under which the track in this file is known",
    
          "serialTitle": "Name of the publication",
    
          "serialISSN": "ISSN-code for this electronic version of the magazine.",
    
          "issueNumber": "Issue/volume number, as in publication.",
    
          "issn": "International Standard Serial Number of the series",
          "seriesName": "Full name of the series",
          "volumeNumber": "Volume number of this publication",
    
          "institution": "The institution that published the dissertation",
          "thesisPlace": "City where the thesis defence took place",
          "thesisYear": "Year on which the thesis defence took place",
    
          "claimId": "If you have received a reference number from us, enter it here"
      },
    
        "errors": {
            "errorsLeft": "There are errors in the information entered to the form",
    
            "addAtleastOneSubmission": "Please add at least one publication",
            "identifiers": {
              "isbn": "Erroneous ISBN",
              "issn": "Erroneous ISSN",
              "ismn": "Erroneous ISMN",
              "isrc": "Erroneous ISRC"
            },
            "chooseAtleastOneFile": "Please choose at least one file",
            "contactPersonMandatory": "Name of contact person is required",
            "invalidEmail": "Incorrect email format",
            "titleMandatory": "Title is required",
            "publisherMandatory": "Publisher info is required",
            "emailOrPhoneMandatory": "Either email or phone number of contact person is required",
            
            "missingInformation": "Information about the publication is missing",
    
            "unknownPublicationType": "Unknown publication type.",
            "fileTypeNotAllowed": "The publication cannot be of the file type submitted.",
            "allowedFileTypes": "Allowed file types:",
            
            "uploadFailed": "File uploading failed. If the problem persists, please contact us."
        },
    
      "languages":{
        "English": "English",
        "Spanish": "Spanish",
        "Sami": "Sami",
        "German": "German",
        "Finnish": "Finnish",
        "French": "French",
        "Swedish": "Swedish",
        "Russian": "Russian",
        "Other": "Other or multilingual",
        "Instrumental": "Wordless/Instrumental"
      },
    
      "success": {
    
        "thanks": "Thank you for your submission to the National Library of Finland",
        "link": "Submit another publication"
      },
    
      "feedback": {
        "title": "Feedback about the new deposition form",
        "robot": "I am not a robot",
        "thanks": "Thank you for your feedback!",
        "send": "Send",
        "backToForm": "Back to the deposition form",
        "info": "If you would like us to contact you, you can add your e-mail address below"
      }
    },
    sv: {
        "mainTitle": "Inlämning av elektroniska friexemplar",
        "welcome": "Inlämning av elektroniska friexemplar",
        "contact": "Kontakta oss",
        "nationalLibrary": "Nationalbiblioteket",
        "legalDepositOffice": "Kontaktuppgifter",
        "legalDepositWebsite": "https://www.kansalliskirjasto.fi/sv/friexemplarsbyran#kontaktuppgifter",
        "legalDepositAddress1": "PB 15",
        "legalDepositAddress2": "00014 Helsingfors universitet",
        "legalDepositContactForm": "Formulär för feedback",
        "legalDepositContactFormURL": "#contact",
        "legalDepositEmail": "E-post: vapaakappale(at)helsinki.fi",
        "legalDepositPhone": "Telefon: 02941 44321 rådgivning vardagar kl. 9–12",
      
        "infoFooter": "Inlämningsformuläret har uppdaterats! Du kan ge oss feedback genom att ringa till numret 02941 44321 vardagar kl. 9-12, genom att skicka e-post till adressen vapaakappale(at)helsinki.fi eller genom att fylla i ",
        "infoFooterContactLink": "feedbackformuläret",
        "infoFooterContactLinkURL": "https://luovutuslomake.kansalliskirjasto.fi/feedback.html?lang=sv",
        "oldFormInfo": "Vid behov, hittar du gamla inlämningsformuläret",
        "footerOldFormLink": "här",
      
      
        "infoText": "Börja med att välja publikationstyp till vänster.",
        "infoTextMultiple": "Du kan lämna in flera publikationer/publikationstyper på samma gång.",
      
          "savedItemsInfo": "Hei! Havaitsimme että olet aiemmin jättänyt lomakkeen täyttämisen kesken ja olemme palauttaneet viimeksi täyttämäsi tiedot. Mikäli haluat poistaa aiemmin liittämäsi tiedostot, voit painaa vasemmalta niiden nimen perässä näkyvää rastia. Nämä tiedot on tallennettu oman tietokoneesi selaimeen, eikä niitä säilytetä missään muualla.",
      
        "submitterInfo": "Kontaktuppgifter",
        "contactPerson": "Kontaktperson",
        "fieldRequired": "Obligatoriskt fält",
        "otherFieldRequired": "Ett av fälten är obligatorisk (e-post eller telefonnummer)",
        "email": "E-post",
        "phoneNumber": "Telefonnummer",
        "organization": "Organisation",
      
        "privacyNotice": {
          "title": "Sekretesspolicy",
          "content": [
            "Dina personuppgifter kommer att användas för kontroll och uppföljning av uppfyllandet av överlåtelseskyldigheten samt vid behov för kommunikation gällande materialöverförningen. Du får en bekräftelse av överlämningen per e-post, som även fungerar som bevis på uppfyllandet av överlåtningsskyldigheten, vänligen kontrollera uppgifternas giltighet.",
            "Åtkomsten till uppgifterna är övervakad och begränsad till friexemplarsbyråns personal. Vi överlåter inte dina kontaktuppgifter till utomstående och använder dem inte heller till andra ändamål. Uppgifterna förvaras permanent."
          ]
        },
      
        "submission": "Bifogade uppgifter",
        "file": "Fil",
        "attachments": "Bilagor",
        "attachmentsInfo": "Ifall publikationen har tillhörande bilagor (såsom en pärmbild eller en skild metadatafil) kan du bifoga dem här",
        "publicationsToSubmit": "Material för inlämning",
        "publishingInfo": "Förlagens kontaktinformation",
        "copy": "Kopiera",
        "paste": "Klistra in",
        "hide": "Göm",
        "show": "Visa",
        "edit": "Ändra",
        "remove": "Radera",
        "removeFile": "Radera fil",
        "book": "bok",
        "notes": "not",
        "audio": "musikinspelning",
        "serial": "tidning",
        "other": "annat",
        "basicInformation": "Publikationens grunduppgifter",
        "identifier": "Identifikation",
        "isbn": "ISBN",
        "ismn": "ISMN",
        "title": "Titel",
        "artist": "Artist",
        "subtitle": "Undertitel",
        "publisher": "Förlag",
        "publisher_alt": "Utgivare",
        "publisher_and_alt": "Utgivare",
        "placeOfPublication": "Utgivningsort",
        "yearOfPublication": "Utgivningsår",
        "yearOfPublicationForSerial": "Utgivningsåren",
        "language": "Språk",
        "search": "Sök",

        "searchInfo": {
          "short": {
            "isbn": "Sök information för publikationen enligt ISBN",
            "issn": "Sök information för serien enligt ISSN",
            "ismn": "Sök notbokens uggfiter med ISMN numret",
            "albumIdentifier": "Sök information för albumet enligt identifikationsnummer"
          },
          "long": {
            "isbn": "Notera! När du klickar på “Sök” kommer formuläret att söka våra databaser på ISBN-numret som du har angivit och fylla i alla fält automatiskt ifall data hittas.",
            "serialISSN": "Notera! När du klickar på “Sök” kommer formuläret att söka våra databaser på ISSN-numret som du har angivit och fylla i alla fält automatiskt ifall data hittas.",
            "ismn": "Notera! När du klickar på “Sök” kommer formuläret att söka våra databaser på ISMN-numret som du har angivit och fylla i alla fält automatiskt ifall data hittas.",
            "albumIdentifier": "Notera! När du klickar på “Sök” kommer formuläret att söka våra databaser på identifikationsnumret som du har angivit och fylla i alla fält automatiskt ifall data hittas."
          }
        },
      
        "keywords": "Ämnesord",
        "keywordInfo": "Ämnesorden bör hämtas från den ",
        "ysoForKeywordInfo": "Allmänna finländska ontologin",
        "keywordInfoForSerials": "För tidningar, nyckelord berör hela titeln, icke enskilda volymer",
        "fintoURL": "https://finto.fi/yso/sv/",
      
      
      
      
        "addKeyword": "Lägg till nyckelord",
        "addedKeywords": "Tillagda nyckelord",
        "startWritingKeyword": "Börja med att skriva ämnesordet...",
        "clear": "Töm",
      
        "authors": "Upphovsmän",
        "addAuthor": "Lägg till upphovsman",
        "editAuthor": "Ändra upphovsman",
        "copyAuthor": "Kopiera informationen om upphovsmannen till andra filer",
      
        "personAuthor": "Personuppgifter",
        "organizationAuthor": "Organisation som upphovsman",
        "authorName": "Författarens namn",
        "authorNameShort":  "Författare",
        "authorNameInfo": "Info om författare",
        "authorNameInfoShort": "Info",
          "authorNameOrder": "Efternamn, Förnamn",
        "person": "Person",
        "firstName": "Förnamn",
        "lastName": "Efternamn",
        "organizationName": "Organisationens namn",
      
        "writer": "Författare",
        "illustrator": "Illustratör",
        "translator": "Översättare",
        "editor": "Redaktör",
      
        "albumTitle": "Skivans namn",
        "audioFileTitle": "Namn",
        "audioFileIdentifier": "ISRC",
        "songs": "Låtar / Verk",
      
        "composer": "Kompositör",
        "lyricist": "Sångtextförfattare",
        "arranger": "Arrangör",
      
        "seriesInfo": "Seriens info",
        "isPartOfSeries": "Publikationen tillhör en serie",
        "issn": "ISSN",
        "seriesName": "Seriens namn",
        "issueNumber": "Tidningens nummer (t.ex. 1/2017)",
        "volumeNumber": "Utgivningens nummer",
        "volumes": "Volymerna",
      
        "thesisInfo": "Avhandlingens info",
        "isThesis": "Publikationen är en avhandling",
        "institution": "Läroanstalt",
        "thesisType": "Typ av avhandling",
        "doctoral": "doktorsavhandling",
        "licentiate": "licentiatavhandling",
        "otherThesis": "annan avhandling",
        "thesisPlace": "Disputationens plats",
        "thesisYear": "Disputationens år",
      
        "otherInfo": "Övrig information",
        "url": "Adress på Internet",
        "urlAdditionalInfo": "Enlight {0} skall publikationens ursprungliga förvaringsplats anges.",
        "culturalMaterialActName": "lagen om deponering och förvaring av kulturmaterial",
        "culturalMaterialActUrl": "https://www.finlex.fi/sv/laki/ajantasa/2007/20071433",
        "printIsbn": "Tryckta versionens ISBN",
        "public": "Allmän",
        "isSecret": "Sekretessbelagt",
        "reasonForSecrecy": "Lagrum på vilket sekretessbeläggningen grundar sig på",
        "additionalInfo": "Tilläggsinformation",
      
        "claimId": "Luovutuksen viitenumero",
      
        "startByChoosingFile": "Börja med att välja fil",
        "startByChoosingAudioFile": "Börja med att uppladda åtminstone en låt",
      
      
        "multipleFilesInfo": "Du har valt en eller flera filer. Tillhör filerna till en och samma publikation (så som t.ex. musikinspelningens enskilda  låtar eller en tidnings olika utgivningar) eller är var och en av dessa en skild publikation?",
        "multiplePublicationsAnswer": "Flera enskilda publikationer",
        "singlePublicationAnswer": "En och samma publikation",
        "addFile": "Lägg till en fil",
        "addAudioFile": "Lägg till en låt",
        "addIssue": "Lägg till nummer",
        "addAttachment": "Lägg till en bilaga",
        "bookFilenameRecommendation": "Vi rekommenderar att som filnamn använda publikationens identifikationsnummer (ISBN eller ISSN); eller ifall en sådan saknas, publikationens namn istället.",
        
        "bookFilesInfo": "Notera: ifall boken består av flera filer, kan du lägga till de övriga filerna som bilagor",
        "audioFilesInfo": "Notera: Om du vill bifoga till exempel en omslagsbild av en skiva kan du göra det under rubriken 'Bilagor'",

        "files": "Filerna",
        "fileTitle": "Filens beskrivning eller namn",
      
        "previous": "Föregående",
        "next": "Nästa",
        "close": "Stäng",
        "cancel": "Avbryt",
      
        "formSending": "Skicka in formuläret",
        "givenInformation": "Uppladdade filer",
        "submittedPublications": "Överlåtna verk",
        "checkInformation": "Var god och kontrollera uppgifterna före du skickar in dem.",
        "sendForm": "Skicka",
        "beforeSending": "Vänligen kontrollera informationen innan du skickar in filerna",
      
        "generalHelp": {
              "title": "Lisätietoja",
              "paragraph1": "Och samma på svenska :--D Klikattuasi haluamaasi julkaisutyyppiä, avautuvasta ikkunasta tulee etsiä luovutettava tiedosto. Tiedoston valittuasi aukenee täytettäväksesi lomake julkaisun tiedoista.",
              "paragraph2": "Blii blaa bloo klikkaile ja jep jep jep joo loorem ipsum"
          },
      
        "helpTexts": {
          "isbn": "Publikationens ISBN-nummer. Notera att ISBN-numret skall vara den digitala versionens ISBN-nummer",
          "printIsbn": "Ifall det existerar även en tryckt version av publikationen, dess ISBN nummer",
          "title": "Verkets namn/huvudtitel",
          "subtitle": "Verkets undertitel",
          "publisher": "Namnet på ombudsmannen som är ansvarig för publikationens utgivning eller publikation",
          "placeOfPublication": "Utgivarens hemort",
          "yearOfPublication": "Året då publikationen utgavs",
          "yearOfPublicationForSerial": "Publikationens utgivningsår",
          "language": "Publikationens innehålls huvudsakliga språk",
          "url": "Publikationens online adress, som kan vara en URN-adress eller en annan nätadress. Ifall publikationen inte har någon nätadress, meddelas utgivarens eller distributörens nätadress.",
          "reasonForSecrecy": "Sekretessbeläggningen skall motiveras med lagen",
          "additionalInfo": "Ytterligare information som är relevant till publikationen, som inte förekommer i formulärens andra delar",
      
          "albumTitle": "Namnet som publikationen är känd under",
          "artist": "Huvudartistens namn på låtarna som publikationen innehåller",
          "audioFileTitle": "Namnet för låten",
          "albumIdentifier": "Unik identifikationsnummer för publikationen, så som utgivarens produktnummer",
          "audioFileIdentifier": "ISRC-kod (International Standard Record Code), unik standardiserad  identifikationsnummer för låten",
      
          "ismn": "International Standard Music Number, notpublikationens unika standardiserad identifikationsnummer",
          "noteFileTitle": "T.ex. namnet för låten som filen innehåller.",
      
          "serialTitle": "Publikationens namn",
      
          "serialISSN": "ISSN-identifikationsnummer för tidningens e-version.",
      
          "issueNumber": "Tidningens/delens nummer, så som i publikationen.",
      
          "issn": "Internationational Standard Serial Number, seriens internationellt standardiserad identifikationsnummer",
          "seriesName": "Seriens namn",
          "volumeNumber": "Delens nummer, så som i publikationen",
  
          "institution": "Läroanstalten som publicerat avhandlingen",
          "thesisPlace": "Disputationens ort",
          "thesisYear": "Disputationens år",
  
          "claimId": "Mikäli olet saanut meiltä viitenumeron, syötä se tähän"
        },
      
        "errors": {
          "errorsLeft": "Upladdade publikationer innehåller fel eller information saknas.",
      
          "addAtleastOneSubmission": "Lägg till åtminstone en publikation för inlämning",
          "identifiers": {
            "isbn": "Felaktig ISBN",
            "issn": "Felaktig ISSN",
            "ismn": "Felaktig ISMN",
            "isrc": "Felaktig ISRC"
          },
          "chooseAtleastOneFile": "Var god och välj åtminstone en fil för inlämning",
          "contactPersonMandatory": "Kontaktpersonens namn saknas",
          "invalidEmail": "E-postadressen är av felaktig format",
          "titleMandatory": "Titel saknas",
          "publisherMandatory": "Information om utgivaren saknas",
          "emailOrPhoneMandatory": "Kontaktpersonens telefonnummer eller e-postadress saknas",
      
          "missingInformation": "Information om publikationen är bristfällig",
      
              "unknownPublicationType": "Okänd publikationstyp.",
              "fileTypeNotAllowed": "Publikationen kan inte vara av angiven filformat.",
              "allowedFileTypes": "Tillåtna filformat:",
              
              "uploadFailed": "Uppladdningen av filen misslycades. Ifall problemet upprepas, var god och kontakta oss."
        },
      
        "languages":{
          "English": "Engelska",
          "Spanish": "Spanska",
          "Sami": "Samiska",
          "German": "Tyska",
          "Finnish": "Finska",
          "French": "Franska",
          "Swedish": "Svenska",
          "Russian": "Ryska",
          "Other": "Annat eller flerspråkig",
          "Instrumental": "Utan ord/Instrumental"
        },
      
        "success": {
              "thanks": "Tack för din insats till Nationalbiblioteket!",
              "link": "Skicka in mera friexemplar"
        },
      
        "feedback": {
          "title": "Feedback på det nya inlämningsformuläret",
          "robot": "Jag är inte en robot",
          "thanks": "Tack för din feedback!",
        "send": "Skicka",
        "backToForm": "Tillbaka till inlämningsformuläret",
        "info": "Ifall du vill bli kontaktad, kan du ange din e-postadress"
        }
      }
});

export default strings;
