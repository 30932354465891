import React from "react";
import {Button, Modal} from 'react-bootstrap';

class ModalMessage extends React.Component {
    render() {
        const buttons = this.props.buttons.map((b) => <Button key={b.text} onClick={b.onClick}>{b.text}</Button>)

        return (
            <Modal size="lg" centered show={this.props.show}>
                <Modal.Body>
                    <p>
                        {this.props.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {buttons}
                </Modal.Footer>
            </Modal>
        );
    }
  }

  export { ModalMessage }