import React from "react"
import { BsFillPlusCircleFill } from "react-icons/bs";

import { ItemSelectButton } from "./ItemSelectButton";
import { Item } from "../../Models/Item";
import lang from "../../Localization";

class AddTypeButton extends React.Component {
    constructor(props) {
        super(props);

        this.fileInput = React.createRef();

        this.state = {
            showModal: false
        }
    }

    add = (files) => {
        let i = this.props.appFunctions.createItem(this.props.type, { publicationType: this.props.type });
        i.loading = true;
        files.forEach((f) => { this.addFileToItem(f, i) });
        this.props.appFunctions.addItem(i);
    }

    addFileToItem = async (fileData, item) => {
        this.props.appFunctions.addFileToItem(fileData, item, "main");
    }

    fileDialog = () => {
        this.fileInput.current.click();
    }

    filesSelected = () => {
        if(this.fileInput.current.files.length > 1) {
            const modalButtons = [
                { onClick: this.singlePublicationSelected, text: lang.singlePublicationAnswer },
                { onClick: this.multiplePublicationsSelected, text: lang.multiplePublicationsAnswer },
                { onClick: this.closeModal, text: lang.cancel }
            ];

            this.props.appFunctions.showModalMessage(lang.multipleFilesInfo, modalButtons);
        }
        else {
            this.add([ this.fileInput.current.files[0] ]); // Only one file
        }
    }

    closeModal = () => {
        this.props.appFunctions.hideModalMessage();
    }

    singlePublicationSelected = () => {
        let files = [];
        for(let i = 0; i < this.fileInput.current.files.length; i++) {
            let f = this.fileInput.current.files[i];
            files.push(f);
        }
        this.add(files);

        this.closeModal();
    }

    multiplePublicationsSelected = () => {
        for(let i = 0; i < this.fileInput.current.files.length; i++) {
            let f = this.fileInput.current.files[i];
            this.add([f]);
        }
        this.closeModal();
    }
  
    render() {
        let shownItems = this.props.items.filter((it) => { return it.publicationType === this.props.type }).map((it) => {
            return <ItemSelectButton appFunctions={this.props.appFunctions} icon={Item.getType(this.props.type).getIcon()} item={it} selectedItem={this.props.selectedItem} key={it.id} forceShowErrors={this.props.forceShowErrors} />;
        });
        return (
            <div>
                <input type="file" multiple ref={this.fileInput} style={{display: "none" }} onChange={ () => { this.filesSelected() } } />
                <span className="type-select-button" onClick={() => this.fileDialog()}>
                    <BsFillPlusCircleFill />
                    <span className="type-select-name">
                        {Item.getType(this.props.type).getIcon()}
                        <span style={{marginLeft: "5px"}}>{lang[this.props.type]}</span>
                    </span>
                </span>

                <div>
                    {shownItems}
                </div>
            </div>
        );
    }
  }

  export { AddTypeButton }