import React from "react";

import '../Styles/Keywords.css';

class KeywordRemoveButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseHover: false
        };
    }

    removeKeyword = () => {
        this.props.removeKeyword(this.props.word);
    }

    mouseEnter = () => {
        this.setState({mouseHover: true});
    }
    mouseLeave = () => {
        this.setState({mouseHover: false});
    }
  
    render() {
        return (
            <div className="keyword-display keyword-remove" onClick={this.removeKeyword} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                {this.props.word}
                {this.state.mouseHover ? <span className="keyword-remove-button-addition">x</span> : ""}
            </div> 
        );
    }
  }

  export { KeywordRemoveButton }