import React from "react"

import {Button} from "react-bootstrap";
import {ContactFormField} from "../FormElements/ContactFormField";
import lang from '../Localization';

class ContactInfoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            forceShowErrors: false
        }
    }
    nextPageAllowed = () => {
        return this.props.contactPerson.person && this.props.contactPerson.email
    }

    prev = () => {
        this.props.appFunctions.selectPage("files");
    }
    next = () => {
        this.setState({forceShowErrors: true});
        if(this.nextPageAllowed()) this.props.appFunctions.selectPage("confirm");
    }
  
    render() {
        return (
            <div>
                <h3>{lang.submitterInfo}</h3>
                <span style={{color: "#ff0000"}}>*</span> = {lang.fieldRequired}
                <ContactFormField required={true} contactPerson={this.props.contactPerson} labelText={lang.contactPerson} field={"person"} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
                <ContactFormField required={true} contactPerson={this.props.contactPerson} labelText={lang.email} field={"email"} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
                <ContactFormField contactPerson={this.props.contactPerson} labelText={lang.phoneNumber} field={"phone"} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
                <ContactFormField contactPerson={this.props.contactPerson} labelText={lang.organization} field={"organization"} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
                <Button variant="success" className="page-change-button" onClick={ () => {this.prev()} }>{lang.previous}</Button>
                <Button style={{marginLeft: 10}} variant="success" className="page-change-button" onClick={ () => {this.next()} }>{lang.next}</Button>

                <div id="privacy-notice">
                    <h3>{lang.privacyNotice.title}</h3>
                    <p>{lang.privacyNotice.content[0]}</p>
                    <p>{lang.privacyNotice.content[1]}</p>
                </div>
            </div>
        );
    }
  }

  export { ContactInfoPage }