import React from "react";
import { ItemFormGroup } from '../FormElements/ItemFormGroup';
import { KeywordField } from '../FormElements/KeywordField';
import { AttachmentsField } from '../FormElements/AttachmentsField';

import { Row, Col, Form } from 'react-bootstrap'
import lang from '../Localization';

class GeneralFields extends React.Component {
    setIsSecret = (ev) => {
        const val = ev.target.checked;
        this.props.appFunctions.setItemField(this.props.item.id, "isSecret", val);
    }
  
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <AttachmentsField appFunctions={this.props.appFunctions} item={this.props.item} />
                        <KeywordField appFunctions={this.props.appFunctions} item={this.props.item} />
                        <h3>{lang.otherInfo}</h3>
                        
                        <Form.Group controlId="is-secret-checkbox">
                            <Form.Check type="checkbox" label={lang.isSecret} onChange={this.setIsSecret} checked={this.props.item.isSecret} />
                        </Form.Group>
                        <div style={ this.props.item.isSecret ? {} : {display: "none"} }>
                            <ItemFormGroup field={"reasonForSecrecy"} item={this.props.item} appFunctions={this.props.appFunctions} />
                        </div>

                        <ItemFormGroup field={"additionalInfo"} as={"textarea"} maxLength={500} item={this.props.item} appFunctions={this.props.appFunctions} />
                        <div style={{float:"right"}}>
                            {this.props.item.additionalInfo.length} / 500
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
  }

  export { GeneralFields }
