import React from "react"

import {FormControl, InputGroup} from 'react-bootstrap';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";

class BaseFormGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldDirty: false // Field is marked dirty after the user has selected and deselected it. Used to show errors only when the user has had the possibility to fill the field
        }
    }

    setValue = (ev) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onValueChange(value);
    }

    onBlur = () => {
        this.setState({fieldDirty: true});

        if(this.props.onBlur) this.props.onBlur();
    }
  
    render() {
        const requiredInfo = this.props.required ? <span style={{color: "red"}}>*</span> : "";
        return (
            <div>
                <ReactTooltip effect="solid" />
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text className="input-group-text-form">
                            {this.props.labelText}
                            {requiredInfo}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        className={this.props.showErrorClass || (this.props.required && (this.props.forceShowErrors || this.state.fieldDirty) && !this.props.value) ? "input-error" : ""}
                        name={this.props.field}
                        value={this.props.value}
                        aria-label={this.props.labelText}
                        onChange={this.setValue}
                        onBlur={this.onBlur}
                        required={this.props.required}
                        as={this.props.as}
                        maxLength={this.props.maxLength}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text className="input-group-text-form" data-tip={this.props.helpText}>
                            <BsFillQuestionCircleFill />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
  }

  export { BaseFormGroup }