import React from "react";
import {Button} from 'react-bootstrap';
import { BsFillPlusCircleFill } from "react-icons/bs";

import { AttachmentRemoveButton } from './AttachmentRemoveButton';
import lang from '../Localization';

import '../Styles/Attachments.css';

class AttachmentsField extends React.Component {
    constructor(props) {
        super(props);

        this.fileInput = React.createRef();
    }

    filesSelected = () => {
        for(let i = 0; i < this.fileInput.current.files.length; i++) {
            let f = this.fileInput.current.files[i];
            this.props.appFunctions.addFileToItem(f, this.props.item, "attachment");
        }
    }

    fileDialog = () => {
        this.fileInput.current.click();
    }

    render() {
        const currentAttachments = this.props.item.getFiles("attachment").map((f) => {
            return <AttachmentRemoveButton key={f.tmpFilename} file={f} item={this.props.item} appFunctions={this.props.appFunctions}/>
        });
        return (
            <div>
                <h3>{lang.attachments}</h3>
                <input type="file" multiple ref={this.fileInput} style={{display: "none" }} onChange={ () => { this.filesSelected() } } />
                <Button onClick={this.fileDialog} className="add-attachment-button">
                    <BsFillPlusCircleFill />
                    {" " + lang.addAttachment}
                </Button>

                <div>
                    {currentAttachments}
                </div>
            </div>
        );
    }
  }

  export { AttachmentsField }