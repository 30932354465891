import {BsFillQuestionCircleFill, BsBook, BsMic, BsMusicNoteBeamed, BsLayoutTextWindowReverse} from 'react-icons/bs';
import { Author } from './Author';

import dataid from './DataIdentifier';
import {validate} from '../Models/ItemIdentifier';

class Item {
    constructor(data) {
        data = data || {};

        this.id = data.id || dataid(); // If id not given, generate one
        this.publicationType = data.publicationType;
        this.title = data.title || "";
        this.subtitle = data.subtitle || "";
        this.publisher = data.pulisher || "";
        this.placeOfPublication = data.placeOfPublication || "";
        this.yearOfPublication = data.yearOfPublication || "";
        this.language = data.language || "fin";
        this.isbn = data.isbn || "";
        this.printIsbn = data.printIsbn || "";
        this.artist = data.artist || "";
        this.identifier = data.identifier || ""; // This is a generic identifier for the item (like ISBN or ISSN but for types that don't have a standardized identifier type) 
        this.ismn = data.ismn || "";
        this.isPartOfSeries = data.isPartOfSeries || false;
        this.issn = data.issn || "";
        this.seriesName = data.seriesName || "";
        this.volumeNumber = data.volumeNumber || "";
        this.isThesis = data.isThesis || false;
        this.institution = data.institution || "";
        this.thesisType = data.thesisType || "";
        this.thesisPlace = data.thesisPlace || "";
        this.thesisYear = data.thesisYear || "";
        this.urn = data.urn || "";
        this.url = data.url || "";
        this.isSecret = data.isSecret || false;
        this.reasonForSecrecy = data.reasonForSecrecy || "";
        this.additionalInfo = data.additionalInfo || "";
        this.files = [];
        this.keywords = [];

        this.fields = {} // This can be used by different components to save additional information about the fields
    }

    static getType = (name) => {
        const types = {
            "book": Book,
            "audio" : Audio,
            "notes" : Notes,
            "serial" : Serial
        }

        return types[name];
    }

    static createType = (name, data) => {
        switch(name) {
            case "audio":
                return new Audio(data);
            case "notes":
                return new Notes(data);
            case "serial":
                return new Serial(data);
            case "book":
            default:
                return new Book(data);
        }
    }

    toString = () => {
        if(this.title) {
            return this.title;
        }
        else if(this.getFiles("main").length > 0) {
            return this.getFiles("main")[0].originalFilename || " ";
        } else {
            return "";
        }
    }

    addFile = (file, dataFromBackend) => {
        this.files.push(file);

        if(dataFromBackend || false) {
            // Some item types have files that contain info about the item (e.g. music tracks)
            this.addMetadataFoundFromFile(file, dataFromBackend);
        }
    }

    deleteFile = (file) => {
        this.files = this.files.filter((f) => {
            return f !== file;
        });
    }

    addMetadataFoundFromFile = (data) => {
        // Override this for items that have files that contain info about the item
    }

    getFiles = (purpose) => {
        if(!purpose) return this.files;

        return this.files.filter((f) => f.purpose === purpose);
    }

    isValid() {
        if (!this.title || !this.publisher || !this.url || this.getFiles("main").length === 0) {
            return false;
        }

        return true;
    }

    // When getting metadata from the APIs the data sometimes contains extra whitespace and characters that are not part of the actual values
    // This function can be used to remove them (NOTE: this MIGHT sometimes remove characters that are actually part of the data)
    cleanFoundMetadata = (data) => {
        let result = Array.isArray(data) ? [] : {};
        for(const key in data) {
            if(!data[key]) continue; // Value is null or empty string, so nothing to do
            
            if(Array.isArray(data[key]) || (typeof data[key] === 'object' && data[key] !== null)) {
                result[key] = this.cleanFoundMetadata(data[key]);
            } else {
                let val = data[key].trim();

                const unwantedChars = [",", "/", "[", "]", ":"];

                // Remove from end
                while(unwantedChars.includes(val.substring(val.length -1))) {
                    val = val.substring(0, val.length - 1);
                }
                // ...and from beginning
                while(unwantedChars.includes(val.substring(0, 1))) {
                    val = val.substring(1);
                }

                result[key] = val.trim(); 
            }
        }

        return result;
    }

    setMetadataFromQuery = (fieldQueried, data) => {
        // Override this for items that have fields that can be used to query data
    }

    // This should be overriden by extending classes
    static getIcon = () => {
        return <BsFillQuestionCircleFill />
    }

    /**
     * Function to get only the data without the functions
     * NOTE: the data also contains a bit different keys for some items because that's what Varsta expects
     */
    getSubmittableData = () => {
        return {
            publicationType: this.publicationType,
            title: this.title,
            isbn: this.isbn,
            printISBN: this.printIsbn,
            subtitle: this.subtitle,
            publisher: this.publisher,
            placeOfPublication: this.placeOfPublication,
            yearOfPublication: this.yearOfPublication,
            language: this.language,
            ismn: this.ismn,
            isPartOfSeries: this.isPartOfSeries,
            issn: this.issn,
            artist: this.artist,
            identifier: this.identifier,
            seriesName: this.seriesName,
            volumeNumber: this.volumeNumber,
            isThesis: this.isThesis,
            institution: this.institution,
            thesisType: this.thesisType,
            thesisPlace: this.thesisPlace,
            thesisYear: this.thesisYear,
            urn: this.urn,
            url: this.url,
            isSecret: this.isSecret,
            reasonForSecrecy: this.reasonForSecrecy,
            additionalInfo: this.additionalInfo,
            file: this.files.map((f) => f.getSubmittableData()),
            keyword: this.keywords
        }
    }
}

class Book extends Item {
    addFile = (file) => {
        // Books can only have one "main" file
        if(this.getFiles("main").length > 0) file.purpose = "attachment";
        this.files.push(file)
    }

    static getIcon = () => {
        return <BsBook />
    }

    isValid() {
        // For books, we also check the validity of the ISBN IF it has been given
        if(this.isbn && !validate(this.isbn, "isbn")) {
            return false;
        }

        return super.isValid();
    }

    setMetadataFromQuery = (fieldQueried, data) => {
        data = this.cleanFoundMetadata(data);

        if(fieldQueried === "issn") {
            if(data.series) this.seriesName = data.series;
        }
        else {
            // Only other option currently is an ISBN search
            if(data.isbn) this.isbn = data.isbn;
            if(data.title) this.title = data.title;
            if(data.subtitle) this.subtitle = data.subtitle;
            if(data.publisher) this.publisher = data.publisher;
            if(data.publishingPlace) this.placeOfPublication = data.publishingPlace;
            if(data.year) this.yearOfPublication = data.year;
            if(data.keywords && data.keywords.length > 0) this.keywords = data.keywords;

            let authors = data.authors && data.authors.length > 0 ? data.authors.map((a) => {
                let name = "";
                if(a.last && a.first) {
                    name = a.last + ", " + a.first;
                }
                else if(a.last) {
                    name = a.last;
                }
                else if(a.first) {
                    name = a.first;
                }

                return new Author({
                    name: name,
                    writer: a.role.includes("writer"),
                    editor: a.role.includes("editor"),
                    illustrator: a.role.includes("illustrator"),
                    translator: a.role.includes("translator")
                });
            }) : [];
            // Book should always have only one main file
            this.getFiles("main")[0].authors = authors;
        }
    }
}

class Audio extends Item {
    static getIcon = () => {
        return <BsMic />
    }

    setMetadataFromQuery = (fieldQueried, data) => {
        data = this.cleanFoundMetadata(data);

        // Only option here is the generic "identifier search" search (dunno what it actually can find)
        if(data.isbn) this.isbn = data.isbn;
        if(data.title) this.title = data.title;
        if(data.subtitle) this.subtitle = data.subtitle;
        if(data.publisher) this.publisher = data.publisher;
        if(data.publishingPlace) this.placeOfPublication = data.publishingPlace;
        if(data.year) this.yearOfPublication = data.year;
        if(data.keywords && data.keywords.length > 0) this.keywords = data.keywords;
    }

    addMetadataFoundFromFile = (file, data) => {
        if(data.title) this.title = data.title;
        if(data.artist) this.artist = data.artist;
        if(data.fileTitle) file.title = data.fileTitle;
    }

    isValid() {
        // For each file if an identifier is given, check its validity
        let valid = true;
        this.files.forEach((f) => {
            if(f.identifier && !validate(f.identifier, "isrc")) {
                valid = false;
            }
        });

        return valid && super.isValid();
    }
}

class Serial extends Item {
    static getIcon = () => {
        return <BsLayoutTextWindowReverse />
    }

    setMetadataFromQuery = (fieldQueried, data) => {
        data = this.cleanFoundMetadata(data);

        // Only the ISSN can be used as query for serials
        if(data.series) this.title = data.series;
        if(data.publisher) this.publisher = data.publisher;
    }

    isValid() {
        if(!super.isValid()) return false;

        let allFilesValid = true;
        this.files.forEach((f) => {
            if(!f.title) {
                allFilesValid = false;
            }
        });

        return allFilesValid;
    }
}

class Notes extends Item {
    static getIcon = () => {
        return <BsMusicNoteBeamed />
    }

    isValid() {
        // if ISMN has been given, check that it's valid
        if(this.ismn && !validate(this.ismn, "ismn")) {
            return false;
        }

        return super.isValid();
    }

    setMetadataFromQuery = (fieldQueried, data) => {
        data = this.cleanFoundMetadata(data);

        // Only option here is an ISMN search
        if(data.isbn) this.isbn = data.isbn;
        if(data.title) this.title = data.title;
        if(data.subtitle) this.subtitle = data.subtitle;
        if(data.publisher) this.publisher = data.publisher;
        if(data.publishingPlace) this.placeOfPublication = data.publishingPlace;
        if(data.year) this.yearOfPublication = data.year;
        if(data.keywords && data.keywords.length > 0) this.keywords = data.keywords;
    }
}

export { Item, Book, Audio, Serial, Notes }
