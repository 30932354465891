import React from "react";

import {FormControl, InputGroup, Button, Dropdown, Form} from 'react-bootstrap';
import lang from '../Localization';
import {Author} from '../Models/Author';

import '../Styles/Authors.css'

class AddAuthorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newAuthor: new Author(),
            editMode: false,
        };
        // Ref to name input
        this.nameInput = React.createRef();
    }

    setAuthorField = (ev) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;

        // Create a copy of the author so as not to modify the author saved to state
        const newAuthor = new Author(this.state.newAuthor); // Edit mode needs author id
        newAuthor[field] = value;
        this.setState({newAuthor: newAuthor, editMode: this.state.editMode});
    }

    setAuthor = () => {
        // Must provide a name
        if(!this.state.newAuthor.name) return;

        const a = this.state.newAuthor;

        if (this.state.editMode) {
            this.props.file.editAuthor(a);
            // Set edit mode off
            this.props.authorEdit.setState();
        } else {
            this.props.file.addAuthor(a);
        }

        this.setState({newAuthor: new Author(), editMode: false});
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    componentDidUpdate() {
        (() => {
            const {setState, state: {author, mode}} = this.props.authorEdit;
            if (author && mode) {
                // Set this state
                this.setState({newAuthor: new Author(author), editMode: mode});
                // Focus input
                this.nameInput.current.focus();
                // Set parent state author to null and leave editmode on
                setState({author: null, mode: mode});
            }
        })();
    }

    render() {
        const dropdownContent = this.props.roles.map((r) => {
            return <Form.Group key={r} controlId={`is-${r}-checkbox`}>
                    <Form.Check type="checkbox" label={lang[r]} onChange={this.setAuthorField} name={r} checked={this.state.newAuthor[r]} />
                </Form.Group>
        });

        const btnText = this.state.editMode ? lang.editAuthor : lang.addAuthor;

        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        aria-label={this.props.labelText}
                        onChange={this.setAuthorField}
                        value={this.state.newAuthor.name}
                        name="name"
                        placeholder={`${lang.authorName} (${lang.lastName}, ${lang.firstName})`}
                        ref={this.nameInput}
                    />
                    <InputGroup.Append>
                    <Dropdown>
                        <Dropdown.Toggle className="author-role-dropdown">
                            {lang.authorNameInfo}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <div className="author-role-dropdown-content">
                                {dropdownContent}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    </InputGroup.Append>
                    <InputGroup.Append>
                        <Button className="input-group-text-form" onClick={this.setAuthor}>
                            {btnText}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
}

export { AddAuthorField }
