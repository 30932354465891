import dataid from './DataIdentifier';

class FileInfo {
    constructor(data) {
        data = data || {};

        this.id = data.id || dataid(); // If id not given, generate one
        this.originalFilename = data.originalFilename || "";
        this.tmpFilename = data.tmpFilename || "";
        this.purpose = data.purpose || "main";
        this.ok = true; // Default to assuming all is good
        this.data = data.data || null;
        this.authors = [];
        this.title = data.title || "";
        this.identifier = data.identifier || ""; // NOTE: this means an identifier like ISBN or ISRC, don't confuse with the id field which is just for system's internal use
    }

    addAuthor = (a) => {
        this.authors.push(a);
    }

    editAuthor = (editA) => {
        this.authors.some((a, i) => {
            const idEq = a.id === editA.id;
            if (idEq) {
                this.authors[i] = editA;
            }
            return idEq;
        });
    }

    removeAuthor = (a) => {
        const index = this.authors.map((a) => a.id).indexOf(a.id);
        this.authors.splice(index, 1);
    }

    /**
     * Function to get only the data without the functions
     * NOTE: the actual file data is not returned
     * NOTE: the data also contains a bit different keys for some items because that's what Varsta expects
     */
    getSubmittableData = () => {
        return {
            originalFilename: this.originalFilename,
            tmpFilename: this.tmpFilename,
            filePurpose: this.purpose,
            ok: this.ok,
            authors: this.authors.map((a) => a.getSubmittableData()),
            title: this.title,
            identifier: this.identifier
        }
    }
}

export { FileInfo }