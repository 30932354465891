import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import lang from "../Localization";

class LanguageSelect extends React.Component {
    selected = (ev) => {
        const value = ev.target.value;
        this.props.appFunctions.setItemField(this.props.item.id, "language", value);
    }

    render() {
        const languages = [
            { text: lang.languages.Finnish, value: "fin" },
            { text: lang.languages.English, value: "eng" },
            { text: lang.languages.Spanish, value: "spa" },
            { text: lang.languages.Sami, value: "smi" },
            { text: lang.languages.German, value: "ger" },
            { text: lang.languages.French, value: "fre" },
            { text: lang.languages.Swedish, value: "swe" },
            { text: lang.languages.Russian, value: "rus" },
            { text: lang.languages.Other, value: "mul" }
        ];

        if(this.props.additionalOptions) {
            this.props.additionalOptions.forEach((o) => languages.push(o));
        }

        const options = languages.map((l) => <option key={l.value} value={l.value}>{l.text}</option>)
        

        return (
            <div>
                <ReactTooltip effect="solid" />
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text className="input-group-text-form">
                            {lang.language}
                        </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control as="select" value={this.props.item.language} onChange={this.selected}>
                        {options}
                    </Form.Control>
                    
                    <InputGroup.Append>
                        <InputGroup.Text className="input-group-text-form" data-tip={lang.helpTexts["language"]}>
                            <BsFillQuestionCircleFill />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
  }

  export { LanguageSelect }