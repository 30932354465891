import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";

import { BsClipboard, BsPencil } from "react-icons/bs";

import {Author} from '../Models/Author';

import lang from '../Localization';

class AddedAuthordisplay extends React.Component {
    editAuthor = () => {
        // Set parent state author and edit mode on
        this.props.authorEdit.setState({author: new Author(this.props.author), mode: true});
    }

    removeAuthor = () => {
        this.props.file.removeAuthor(this.props.author);
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    copyToOtherFiles = () => {
        const isAlreadyAdded = (author, file) => {
            // Check if author with same info (name and roles) has been added to a given file
            let added = false;
            file.authors.forEach((a) => {
                if(a.name === author.name &&
                        a.writer === author.writer && a.editor === author.editor && a.translator === author.translator && a.illustrator === author.illustrator &&
                        a.artist === author.artist && a.composer === author.composer && a.lyricist === author.lyricist && a.arranger === author.arranger) {
                    added = true;
                }
            });
            return added;
        }
        
        this.props.item.files.forEach((f) => {
            if(f !== this.props.file && !isAlreadyAdded(this.props.author, f)) {
                let a = new Author();
                a.copy(this.props.author);
                f.addAuthor(a);
            }
        });
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    createAuthorIcons = () => {
        return <table className="author-icons-table">
            <tbody>
            { this.props.item.publicationType === "book" ?
                <tr>
                    <td className="author-icon">{this.props.author.writer ? <span title={lang.writer}>&#x1F58A;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.editor ? <span title={lang.editor}>&#x270D;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.illustrator ? <span title={lang.illustrator}>&#x1F58C;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.translator? <span title={lang.translator}>&#x1F5E8;</span> : <span></span> }</td>
                </tr> : false }
                { this.props.item.publicationType === "audio" || this.props.item.publicationType === "notes" ?
                <tr>
                    <td className="author-icon">{this.props.author.artist ? <span title={lang.artist}>&#x1F3A4;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.arranger ? <span title={lang.arranger}>&#x1f3bc;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.lyricist ? <span title={lang.lyricist}>&#x1F5E8;</span> : <span></span> }</td>
                    <td className="author-icon">{this.props.author.composer ? <span title={lang.composer}>&#x1F3B8;</span> : <span></span> }</td>
                </tr> : false }
            </tbody>
        </table>
    }

    render() {
        // In edit mode, buttons disabled
        const editMode = !!this.props.authorEdit.state.mode;

        return (
            <div>
                <table className="added-authors-table">
                    <tbody>
                        <tr>
                            <td>
                                {this.props.author.name}
                            </td>
                            <td className="author-icon-display">
                                {this.createAuthorIcons()}
                            </td>
                            <td className="remove-author-button">
                                <ButtonGroup>
                                    { this.props.allowCopy ? <Button className="copy-author-button" title={lang.copyAuthor} onClick={this.copyToOtherFiles} disabled={editMode}>
                                        <BsClipboard ></BsClipboard>
                                    </Button> : null  } 
                                    <Button title={lang.edit} variant="secondary" onClick={this.editAuthor} disabled={editMode}><BsPencil></BsPencil></Button>
                                    <Button title={lang.remove} variant="danger" onClick={this.removeAuthor} disabled={editMode}>X</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
  }

  export { AddedAuthordisplay }