import React from "react";
import { ItemFormGroup } from '../FormElements/ItemFormGroup';
import { IdentifierFormGroup } from '../FormElements/IdentifierFormGroup';
import { GeneralFields } from './GeneralFields';
import { LanguageSelect } from "../FormElements/LanguageSelect";
import { NotesFileInfo } from "../FormElements/NotesFileInfo";
import { AddFilesButton } from "../FormElements/AddFilesButton";
import { UrlField } from "../FormElements/UrlField";

import { Row, Col } from 'react-bootstrap';
import lang from '../Localization';

class NotesView extends React.Component {
    render() {
        const files = this.props.item.getFiles("main").map((f) => <NotesFileInfo appFunctions={this.props.appFunctions} key={f.tmpFilename} item={this.props.item} file={f} />)

        return (
            <div>
                <Row>
                    <Col md>
                        <h3>{lang.basicInformation}</h3>
                        <div>
                            <span style={{color:"red"}}>*</span> = {lang.fieldRequired}
                        </div>
                        <IdentifierFormGroup identifier={"ismn"} needsToBeValid={true} {...this.props} />
                        <ItemFormGroup required={true} field={"title"} {...this.props} />
                        <ItemFormGroup required={true} field={"publisher"} {...this.props} />
                        <ItemFormGroup field={"placeOfPublication"} {...this.props} />
                        <ItemFormGroup field={"yearOfPublication"} {...this.props} />
                        <LanguageSelect {...this.props} additionalOptions={ [{ text: lang.languages.Instrumental, value: "inst" }] } />
                    </Col>
                    <Col md>
                        <h3>{lang.files}</h3>
                        {files}
                        <div>
                            <AddFilesButton message={lang.addFile} item={this.props.item} appFunctions={this.props.appFunctions} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UrlField {...this.props} />
                    </Col>
                </Row>

                <GeneralFields item={this.props.item} appFunctions={this.props.appFunctions} />
            </div>
        );
    }
  }

  export { NotesView }