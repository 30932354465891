import React from "react"
import {Button, Alert} from 'react-bootstrap';
import lang from '../../Localization';

import '../../Styles/Sidebar.css';

import { AddTypeButton } from "./AddTypeButton"

class Sidebar extends React.Component {
    next = () => {
        this.props.appFunctions.nextPage();
    }

    render() {
        let allValid = true;
        this.props.items.forEach((i) => {
            if(!i.isValid()) allValid = false;
        });
        let showError = !allValid && this.props.forceShowErrors;
        return (
            <div id="sidebar">
                <div id="sticky-sidebar-wrapper">
                    <div>
                        <AddTypeButton type={"book"} {...this.props} />
                        <AddTypeButton type={"audio"} {...this.props} />
                        <AddTypeButton type={"serial"} {...this.props} />
                        <AddTypeButton type={"notes"} {...this.props} />
                    </div>
                    <div style={{color: "#ff0000"}}>
                        { showError ? lang.errors.errorsLeft : false }
                    </div>
                    <div style={ this.props.showNext ? { } : { display: "none" } }>
                        <Button style={{float:"right"}} variant="success" className="page-change-button" onClick={ () => {this.next()} }>{lang.next}</Button>
                    </div>

                    <div style={{clear: "both", marginBottom: "15px"}}></div>

                    <Alert variant="success">
                        <Alert.Heading>{lang.errors.allowedFileTypes}</Alert.Heading>
                        <strong>{lang.book}</strong>: pdf, epub, txt<br/>
                        <strong>{lang.audio}</strong>: mp3, wav, ogg, wma, flac, aiff<br/>
                        <strong>{lang.serial}</strong>: pdf, epub<br/>
                        <strong>{lang.notes}</strong>: pdf, epub<br/>
                    </Alert>
                </div>
            </div>
        );
    }
  }

  export { Sidebar }