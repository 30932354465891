import React from "react";
import {Row, Col} from "react-bootstrap";

import { BaseFormGroup } from "./BaseFormGroup";
import { FileActions } from "./FileActions";
import lang from '../Localization';

import '../Styles/FileInfo.css';

class SerialFileInfo extends React.Component {
    constructor(props) {
        super(props);

        if(props.file.shown === undefined) props.file.shown = true; // Default to showing everything
    }

    setValue = (field, value) => {
        this.props.file[field] = value;
        this.props.appFunctions.itemUpdated(this.props.item);
    }

    render() {
        return (
            <div className="file-info">
                <div className="file-action-bar">
                    {this.props.file.originalFilename}
                    <FileActions item={this.props.item} file={this.props.file} appFunctions={this.props.appFunctions} />
                </div>
                <div style={this.props.file.shown ? {} : {display: "none"}}>
                    <Row>
                        <Col>
                            <BaseFormGroup required={true} forceShowErrors={this.props.forceShowErrors} helpText={lang.helpTexts.issueNumber} labelText={lang.issueNumber} field={"title"} value={this.props.file.title} onValueChange={ (v) => this.setValue("title", v) } />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
  }

  export { SerialFileInfo }