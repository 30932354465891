import React from "react"
import {Modal, Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";

import lang from '../Localization';

import notChecked from '../images/notrobot_checkbox_notchecked.png';
import checked from '../images/notrobot_checkbox_checked.png'

class FeedbackFormDisplay extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            feedback: "",
            notRobot: false,
            robotCheck: "",
            showThanks: false
        };
    }
    sendForm = () => {
        if(!this.state.notRobot || this.state.feedback.length === 0 || this.state.robotCheck !== "") return;
        
        this.setState({ showThanks: true });
        this.props.backend.submitFeedback(this.state.feedback, this.state.email, this.state.notRobot, this.state.robotCheck);
    }
    closeForm = () => {
        this.setState({ email: "", feedback: "", notRobot: false, showThanks: false });
        this.props.appFunctions.closeFeedbackForm();
    }

    setEmail = (ev) => {
        this.setState({email: ev.target.value});
    }
    setFeedback = (ev) => {
        this.setState({feedback: ev.target.value});
    }
    
    setRobotCheck = (ev) => {
        this.setState({robotCheck: ev.target.value});
    }

    enableNotRobot = (ev) => {
        this.setState({notRobot: true});
    }

    render() {
        let robotCheckbox = this.state.notRobot ? checked : notChecked;
        return (
            <Modal size="lg" centered show={this.props.show}>
                <Modal.Body>
                    <div style={this.state.showThanks ? {display:"none"} : {}}>
                        <h1>{lang.feedback.title}</h1>
                        <FormGroup className="mb-3">
                            <FormControl disabled={this.state.showThanks} as="textarea" onChange={this.setFeedback} value={this.state.feedback} />
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <FormLabel>{lang.feedback.info}</FormLabel>
                            <FormControl disabled={this.state.showThanks} placeholder={lang.email} onChange={this.setEmail} value={this.state.email} />
                        </FormGroup>

                        <img style={{width:"30px"}} src={robotCheckbox} alt={lang.feedback.robot} onClick={this.enableNotRobot} />
                        {lang.feedback.robot}

                        <input type="hidden" onChange={this.setRobotCheck} value={this.state.robotCheck} />
                    </div>
                    <div style={this.state.showThanks ? {} : {display: "none"}}>
                        <h1>{lang.feedback.thanks}</h1>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={this.state.showThanks ? {display:"none"} : {}} onClick={this.sendForm}>{lang.feedback.send}</Button>
                    <Button onClick={this.closeForm}>{lang.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
  }

  export { FeedbackFormDisplay }