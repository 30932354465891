import React from "react";
import { ItemFormGroup } from "./ItemFormGroup";

import lang from "../Localization";

class UrlField extends React.Component {
    render() {
        return (
            <div>
                <h3>{lang.url}</h3>
                <ItemFormGroup required={true} field={"url"} {...this.props} />
                <div style={{marginTop: "-15px", marginBottom: "15px"}}>
                {lang.formatString(lang.urlAdditionalInfo, <em><a target="_blank" rel="noreferrer" href={lang.culturalMaterialActUrl}>{lang.culturalMaterialActName}</a></em>)}
                </div>
            </div>
        );
    }
  }

  export { UrlField }