import axios from "axios";
import Queue from "promise-queue";
import { backendURL, metadataQueryURL, feedbackURL } from '../Config';
/*
 * Uploads files to the backend. The files are sent one by one using a promise queue (had weird problems when the files were sent all at once)
 * Note: the publicationType is used to determine which kind of files are allowed
 */
let fileSendQueue = new Queue(1);
let uploadStatus = {
    sent: 0,
    total: 0,
    currentProgress: 0
}
let statusChangeListener = null;
let sendFileToBackend = (file, publicationType) => {
    const formData = new FormData();
    formData.append(
        "myFile",
        file.data,
        file.data.name
    );
    formData.append("action", "uploadFile");
    formData.append("filePurpose", file.purpose);
    formData.append("publicationType", publicationType);

    uploadStatus.total += 1;
    if(statusChangeListener) statusChangeListener(uploadStatus);

    return fileSendQueue.add(() => { 
        return axios.post(backendURL, formData, { onUploadProgress: (ev) => { 
            uploadStatus.currentProgress = ev.loaded / ev.total;
            if(statusChangeListener) statusChangeListener(uploadStatus);
        }}).finally((resp) => {
            uploadStatus.sent += 1;
            if(uploadStatus.sent === uploadStatus.total) {
                // Everything sent, reset status
                uploadStatus.sent = 0;
                uploadStatus.total = 0;
                uploadStatus.currentProgress = 0;
            }
            if(statusChangeListener) statusChangeListener(uploadStatus);
            return resp;
        });
    });
}

let getMetadata = (field, query) => {
    return axios.get(metadataQueryURL + "?field=" + field + "&query=" + query);
}

let submitData = (items, contactPerson, lang) => {
    const formData = new FormData();
    formData.append("action", "receiveForm");
    formData.append("items", JSON.stringify(items.map((i) => i.getSubmittableData())));
    formData.append("contactPerson", JSON.stringify(contactPerson));
    formData.append("formLanguage", lang);

    return axios.post(backendURL, formData);
}

let submitFeedback = (feedback, email, notRobot, robotCheck) => {
    const formData = new FormData();
    formData.append("feedback", feedback);
    formData.append("email", email);
    formData.append("notRobot", notRobot);
    formData.append("robotCheck", robotCheck);

    return axios.post(feedbackURL, formData);
}

let defaultExport = {
    sendFileToBackend: sendFileToBackend,
    getMetadata: getMetadata,
    submitData: submitData,
    getUploadStatus: () => { return uploadStatus },
    setUploadStatusListener: (listener) => { statusChangeListener = listener; },
    submitFeedback: submitFeedback
}
export default defaultExport;