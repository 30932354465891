import React from "react";
import { Form } from "react-bootstrap";
import { ItemFormGroup } from "./ItemFormGroup";

import lang from "../Localization";

class ThesisInfo extends React.Component {
    setIsThesis = (ev) => {
        const isThesis = ev.target.checked;
        this.props.appFunctions.setItemField(this.props.item.id, "isThesis", isThesis);
    }

    setThesisType = (ev) => {
        const type = ev.target.value;
        this.props.appFunctions.setItemField(this.props.item.id, "thesisType", type);
    }

    render() {
        return (
            <div>
                <h3>{lang.thesisInfo}</h3>

                <Form.Group controlId="is-thesis-checkbox">
                    <Form.Check type="checkbox" label={lang.isThesis} checked={this.props.item.isThesis} onChange={this.setIsThesis} />
                </Form.Group>
                <div style={ this.props.item.isThesis ? {} : {display: "none"} }>
                    <ItemFormGroup field={"institution"} item={this.props.item} appFunctions={this.props.appFunctions} />
                    
                    <div>
                        <Form.Check checked={this.props.item.thesisType==="doctoral"} inline type="radio" label={lang.doctoral} value="doctoral" name="thesis-type" id="thesis-octoral" onChange={this.setThesisType} />
                        <Form.Check checked={this.props.item.thesisType==="licentiate"} inline type="radio" label={lang.licentiate} value="licentiate" name="thesis-type" id="thesis-licentiate" onChange={this.setThesisType} />
                        <Form.Check checked={this.props.item.thesisType==="other"} inline type="radio" label={lang.otherThesis} value="other" name="thesis-type" id="thesis-other" onChange={this.setThesisType} />
                    </div>
                    <div style={ this.props.item.thesisType === "doctoral" ? {} : {display:"none"} }>
                        <ItemFormGroup field={"thesisPlace"} item={this.props.item} appFunctions={this.props.appFunctions} />
                        <ItemFormGroup field={"thesisYear"} item={this.props.item} appFunctions={this.props.appFunctions} />
                    </div>
                </div>
            </div>
        );
    }
  }

  export { ThesisInfo }