import React from "react"

import logo from '../images/kk_logo.png'

import lang from '../Localization';

class InfoDisplay extends React.Component {
    render() {
        return (
            <div style={{textAlign: "center"}}>
                <img src={logo} alt="Kansalliskirjaston logo" style={{width: "150px"}} />
                <h1>{lang.welcome}</h1>
                <h2>{lang.infoText}</h2>
                <h2>{lang.infoTextMultiple}</h2>
            </div>
        );
    }
  }

  export { InfoDisplay }