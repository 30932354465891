import React from "react";
import { Form } from "react-bootstrap";
import { ItemFormGroup } from "./ItemFormGroup";
import { IdentifierFormGroup } from "./IdentifierFormGroup";

import lang from "../Localization";

class SeriesInfo extends React.Component {
    setIsPartOfSeries = (ev) => {
        const isPart = ev.target.checked;
        this.props.appFunctions.setItemField(this.props.item.id, "isPartOfSeries", isPart);
    }
    render() {
        return (
            <div>
                <h3>{lang.seriesInfo}</h3>
                <Form.Group controlId="is-part-of-series-checkbox">
                    <Form.Check type="checkbox" label={lang.isPartOfSeries} checked={this.props.item.isPartOfSeries} onChange={this.setIsPartOfSeries} />
                </Form.Group>
                <div style={ this.props.item.isPartOfSeries ? {} : {display: "none"} }>
                    <IdentifierFormGroup identifier={"issn"} needsToBeValid={true} item={this.props.item} appFunctions={this.props.appFunctions} />
                    <ItemFormGroup field={"seriesName"} item={this.props.item} appFunctions={this.props.appFunctions} />
                    <ItemFormGroup field={"volumeNumber"} item={this.props.item} appFunctions={this.props.appFunctions} />
                </div>
            </div>
        );
    }
  }

  export { SeriesInfo }